import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateTargetAchievement,
  IEditTargetAchievement,
} from "src/services/targetAchievement/targetAchievement.model";
import targetAchievementService from "src/services/targetAchievement/targetAchievement.service";

export const getAdmissionTargetAchievementData = createAsyncThunk(
  "target-achievement/getAdmissionTargetAchievementData",
  async (data: URLSearchParams | {}) => {
    return targetAchievementService.getAdmissionTargetAchievementData(data);
  }
);

export const createNewAdmissionTargetAchievement = createAsyncThunk(
  "target-achievement/createNewAdmissionTargetAchievement",
  async (data: ICreateTargetAchievement) => {
    return targetAchievementService.createNewAdmissionTargetAchievement(data);
  }
);

export const editAdmissionTargetAchievementById = createAsyncThunk(
  "target-achievement/editAdmissionTargetAchievementById",
  async (data: IEditTargetAchievement) => {
    return targetAchievementService.editAdmissionTargetAchievementById(data);
  }
);

export const getAllTargetAchievementReportData = createAsyncThunk(
  "target-achievement/getAllTargetAchievementReportData",
  async (data: URLSearchParams | {}) => {
    return targetAchievementService.getAllTargetAchievementReportData(data);
  }
);
