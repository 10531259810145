import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import {
  ICreateTargetAchievement,
  IEditTargetAchievement,
  ITargetRecord,
  ITargetResponse,
} from "./targetAchievement.model";

class TargetAchievementService {
  ENDPOINT = config.baseApiMasters + "/target-achievement";

  public getAdmissionTargetAchievementData = async (
    data: any
  ): Promise<IApiResponse<ITargetRecord[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({
      url,
      method: "GET",
      params: data.searchParams ? data.searchParams : data,
    }).then((res) => {
      return res.data;
    });
  };

  public createNewAdmissionTargetAchievement = async (
    data: ICreateTargetAchievement
  ): Promise<IApiResponse<ITargetResponse>> => {
    const url = `${this.ENDPOINT}/create-branch-wise-target-achievement`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editAdmissionTargetAchievementById = async (
    data: IEditTargetAchievement
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/${data.id}/admission-target-achievement`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public getAllTargetAchievementReportData = async (
    data: any
  ): Promise<IApiResponse<ITargetRecord[]>> => {
    const url = `${this.ENDPOINT}/target-achievement-report`;
    return request({
      url,
      method: "GET",
      params: data.searchParams ? data.searchParams : data,
    }).then((res) => {
      return res.data;
    });
  };
}

export default new TargetAchievementService();
