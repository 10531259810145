import { Button, Card, Col, message, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TableComponent from "src/components/DataTable";
import { AppDispatch } from "src/state/app.model";
import {
  bufferURLDecode,
  bufferURLEncode,
  convertTo12HourFormat,
} from "src/utils/helperFunction";
import StudentMobileNumber from "src/components/StudentMobileNumber";
import { useAppSelector } from "src/state/app.hooks";
import { userBatchSelector } from "src/state/userBatch/userBatch.reducer";
import { searchUserBatchData } from "src/state/userBatch/userBatch.action";
import {
  examPaperList,
  rejoinExamForStudent,
} from "src/state/ExamBatch/exambatch.action";
import { examBatchSelector } from "src/state/ExamBatch/exambatch.reducer";
import { IExamPaperList } from "src/services/ExamBatch/ExamBatch.model";
import { maximumExamRejoinAttempts } from "src/utils/constants/constant";

const ExamBatchStudent = () => {
  const { batch_id, exam_paper_id } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userBatchState = useAppSelector(userBatchSelector);
  const batchExamtState = useAppSelector(examBatchSelector);
  const [examPaperStudentIds, setExamPaperStudentIds] = useState<number[]>([]);

  useEffect(() => {
    dispatch(searchUserBatchData({ noLimit: true }));
  }, [batch_id]);

  const currentBatchData = userBatchState.searchData.data.rows.find(
    (d) => d.id === Number(batch_id)
  );

  useEffect(() => {
    dispatch(examPaperList(Number(exam_paper_id)));
  }, [exam_paper_id]);

  const newColumnsBatchWiseStudents: ColumnsType<IExamPaperList> = [
    {
      title: "No.",
      align: "center",
      width: "5%",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "GR ID",
      dataIndex: ["admission"],
      className: "admission",
      key: "gr_id",
      width: "5%",
      align: "center",
      render: (record) => {
        return <>{record?.gr_id}</>;
      },
    },
    {
      title: "Student Name",
      render: (record) => {
        return (
          <>
            <span>
              {record?.admission?.first_name +
                " " +
                record?.admission?.middle_name +
                " " +
                record?.admission?.last_name}
            </span>
            <StudentMobileNumber
              studentMobileNumber={String(record?.admission?.mobile_no)}
            />
          </>
        );
      },
    },
    {
      title: "Obtain Marks",
      dataIndex: "obtain_marks",
      className: "obtain_marks",
      align: "center",
      width: "5%",
      render: (record) => {
        return record !== null ? <>{record}</> : <>{""}</>;
      },
    },
    {
      title: "Rejoin Exam Attempts",
      dataIndex: "rejoin_exam_attempts",
      className: "rejoin_exam_attempts",
      align: "center",
      width: "5%",
      render: (record) => {
        return record !== null ? <>{record}</> : <>{""}</>;
      },
    },
    {
      title: "Exam Time",
      dataIndex: "student_exam_time",
      className: "student_exam_time",
      align: "center",
      width: "5%",
      render: (record) => {
        return record !== null ? (
          <>{convertTo12HourFormat(record)}</>
        ) : (
          <>{""}</>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "5%",
      align: "center",
      render: (record) => {
        return (
          <>
            {
              <div>
                <Button
                  onClick={() => {
                    const backURL = bufferURLEncode(
                      `${location.pathname}${location.search}`
                    );
                    navigate(
                      `/batch/${batch_id}/${exam_paper_id}/${record.id}/answersheet?r=${backURL}`,
                      {
                        replace: true,
                        state: {
                          is_exam_submitted: record.is_exam_submitted,
                        },
                      }
                    );
                  }}
                >
                  Answer Sheet
                </Button>
                {!record.is_exam_submitted && (
                  <p className="exam-not-submit-msg">Exam not Attended</p>
                )}
              </div>
            }
          </>
        );
      },
    },
  ];
  const handleRejoinExam = (examPaperStudentIds: number[]) => {
    if (!examPaperStudentIds.length) {
      message.error("Please select student to rejoin exam");
    }
    const rejoinPayLoad = {
      examPaperStudentIds: examPaperStudentIds,
      exam_paper_id: Number(exam_paper_id),
    };
    dispatch(rejoinExamForStudent(rejoinPayLoad)).then((res) => {
      if (res.payload) {
        message.success("Exam rejoin successfully");
        dispatch(examPaperList(Number(exam_paper_id)));
        setExamPaperStudentIds([]);
      }
    });
  };

  return (
    <div className="rnw-main-content">
      <Row align="middle" justify="space-between" gutter={24} className="mb-20">
        <Col xs={16} sm={14}>
          {currentBatchData && (
            <>
              <h2 className="rnw-page-title rnw-page-card-title">
                <span>{currentBatchData?.name}</span>
              </h2>
              <span>
                (
                {currentBatchData.user.first_name +
                  " " +
                  currentBatchData.user.last_name}{" "}
                - {currentBatchData.subcourse.name})
              </span>
            </>
          )}
        </Col>
        <Col xs={8} sm={6} className="text-align-right">
          <div>
            <Button
              type="primary"
              disabled={!examPaperStudentIds.length}
              onClick={() => handleRejoinExam(examPaperStudentIds)}
            >
              <i className="fa fa-rotate-right back-icon"></i>{" "}
              <span className="gx-d-none gx-d-sm-inline-block">Rejoin</span>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const url = searchParams.get("r");
                if (url) {
                  const backURL = bufferURLDecode(url);
                  navigate(backURL);
                } else {
                  const currentPath = window.location.pathname;
                  const segments = currentPath.split("/");
                  const targetSegment = segments[1];
                  if (targetSegment == "batch") {
                    navigate("/batch");
                  } else {
                    navigate("/my-team-batches");
                  }
                }
              }}
            >
              <i className="fa fa-arrow-left back-icon"></i>{" "}
              <span className="gx-d-none gx-d-sm-inline-block">Back</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Card className="rnw-card table-card cheque-amount gx-mb-0">
        <TableComponent
          columns={newColumnsBatchWiseStudents}
          dataSource={
            (batchExamtState.examPaperList?.data &&
              batchExamtState?.examPaperList?.data) ||
            []
          }
          loading={false}
          rowKey="id"
          rowSelection={{
            selectedRowKeys: examPaperStudentIds,
            onChange: (exam_paper_ids: number[]) => {
              setExamPaperStudentIds(exam_paper_ids);
            },
            getCheckboxProps: (record: IExamPaperList) => ({
              disabled:
                record.rejoin_exam_attempts >= maximumExamRejoinAttempts,
            }),
          }}
        />
      </Card>
    </div>
  );
};

export default ExamBatchStudent;
