import { useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Skeleton,
  DatePicker,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import ContainerHeader from "src/components/ContainerHeader";
import moment from "moment";
import { ICreateTargetAchievement } from "src/services/targetAchievement/targetAchievement.model";
import {
  createNewAdmissionTargetAchievement,
  getAdmissionTargetAchievementData,
} from "src/state/targetAchievement/targetAchievement.action";
import { IFormValues } from "./AdmissionTarget.model";
import { useLocation } from "react-router-dom";
import {
  clearRemoveMessage,
  targetAdmissionSelector,
} from "src/state/targetAchievement/targetAchievement.reducer";
import TargetRaw from "./TargetRaw";
import { Common, TargetCategoryType } from "src/utils/constants/constant";
import { ability } from "src/ability";

const AdmissionTarget = () => {
  const dispatch = useDispatch<AppDispatch>();
  const branchState = useAppSelector(branchSelector);
  const targetAdmissionState = useAppSelector(targetAdmissionSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const [loading, setLoading] = useState(true);
  const [targetAchievementData, setTargetAchievementData] = useState<any>(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const location = useLocation();
  const initialValuesRef = useRef<IFormValues | null>(null);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isAllBranch: true,
      })
    ).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (targetAdmissionState.createNewAdmissionTargetAchievement.message) {
      if (targetAdmissionState.createNewAdmissionTargetAchievement.hasErrors) {
        message.error(
          targetAdmissionState.createNewAdmissionTargetAchievement.message
        );
      } else {
        message.success(
          targetAdmissionState.createNewAdmissionTargetAchievement.message
        );
      }
      dispatch(clearRemoveMessage());
    }
  }, [targetAdmissionState.createNewAdmissionTargetAchievement.message]);

  const months = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const fetchData = async () => {
    setLoading(true);

    const start_date = moment().year(selectedYear).startOf("year");
    const end_date = moment().year(selectedYear).endOf("year");

    const result = await dispatch(
      getAdmissionTargetAchievementData({
        start_date: start_date.format("YYYY-MM"),
        end_date: end_date.format("YYYY-MM"),
        type: TargetCategoryType.NO_OF_ADMISSIONS,
        noLimit: true,
        orderBy: "name",
        order: "ASC",
      })
    ).unwrap();

    const data = result?.data;
    setTargetAchievementData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, TargetCategoryType.NO_OF_ADMISSIONS]);

  const subcategories = ["Career Course", "45K to 85K", "Small Course"];
  const branchOptions = branchState?.branchesData?.data?.rows;

  useEffect(() => {
    if (branchOptions?.length > 0) {
      form.setFieldsValue({
        rows: branchOptions.map((branch) => ({
          branchCode: branch.id,
          ...months.reduce((acc: Record<string, string>, month) => {
            const data = targetAchievementData?.find(
              (row: any) => row.branch_id === branch.id
            );
            subcategories.forEach((subcat) => {
              acc[`${month}_${subcat}`] = data?.[`${month}_${subcat}`] || "";
            });
            return acc;
          }, {}),
        })),
      });

      initialValuesRef.current = form.getFieldsValue();
    }
  }, [branchOptions, form, months, subcategories, targetAchievementData]);

  const handleYearChange = (date: moment.Moment | null) => {
    if (date) {
      setSelectedYear(date.year());
    }
  };

  const onFinish = async (values: IFormValues) => {
    setIsSubmitting(true);

    const changes: ICreateTargetAchievement["target"] = [];

    values.rows.forEach((row, rowIndex) => {
      const initialRow = initialValuesRef.current?.rows[rowIndex];
      months.forEach((month) => {
        subcategories.forEach((subcat) => {
          const fieldName = `${month}_${subcat}`;
          const currentValue = parseFloat(row[fieldName] as string) || 0;
          const initialValue =
            parseFloat(initialRow?.[fieldName] as string) || 0;

          if (currentValue !== initialValue) {
            const date = moment()
              .year(selectedYear)
              .month(month)
              .endOf("month")
              .toDate();
            changes.push({
              branch_id: row.branchCode,
              name: subcat,
              value: currentValue,
              type: TargetCategoryType.NO_OF_ADMISSIONS,
              status: true,
              year_month: date,
            });
          }
        });
      });
    });

    const payload: ICreateTargetAchievement = { target: changes };

    if (payload.target.length === 0) {
      setIsSubmitting(false);
      return;
    }

    dispatch(createNewAdmissionTargetAchievement(payload))
      .then(() => {
        fetchData();
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const initialData = useMemo(() => {
    return branchOptions.map((branch) => ({
      branchCode: branch.id,
      ...months.reduce((acc: Record<string, string>, month) => {
        const data = targetAchievementData?.find(
          (row: any) => row.branch_id === branch.id
        );
        subcategories.forEach((subcat) => {
          acc[`${month}_${subcat}`] = "";
        });
        if (data) {
          return { ...acc, ...data };
        } else {
          return acc;
        }
      }, {}),
    }));
  }, [targetAchievementData, branchOptions]);

  return (
    <>
      <Row
        align="middle"
        gutter={24}
        justify="space-between"
        className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"
      >
        <Col className="gx-mb-md-1">
          <ContainerHeader title={"Branch-Wise Admission Target"} />
        </Col>

        <Col>
          <DatePicker
            picker="year"
            value={moment(selectedYear, "YYYY")}
            onChange={handleYearChange}
            style={{ width: 120 }}
          />
        </Col>
      </Row>

      {loading ? (
        <Skeleton active />
      ) : (
        branchOptions?.length > 0 && (
          <Skeleton loading={loading} active avatar>
            <Form
              form={form}
              name="admissionTargetForm"
              initialValues={{
                rows: initialData,
              }}
              className="table-striped"
              onFinish={onFinish}
            >
              <Card className="rnw-card table-card">
                <div className="exam-result-table" style={{ overflow: "auto" }}>
                  <table className="faculty-signing">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center", width: "60px" }}>
                          Sr No
                        </th>
                        <th style={{ textAlign: "center", width: "120px" }}>
                          Branch Code
                        </th>
                        {months.map((month, index) => (
                          <th key={index} style={{ textAlign: "center" }}>
                            {month}
                            <div style={{ marginBottom: "10px" }} />
                            <div style={{ display: "flex" }}>
                              {subcategories.map((subcat, subIndex) => (
                                <div
                                  className="faculty-signing-th"
                                  key={subIndex}
                                  style={{ width: "100%" }}
                                >
                                  {subcat}
                                </div>
                              ))}
                            </div>
                          </th>
                        ))}
                        <th style={{ textAlign: "center" }} colSpan={3}>
                          Total
                          <div style={{ marginBottom: "10px" }} />
                          <div style={{ display: "flex" }}>
                            {subcategories.map((subcat, subIndex) => (
                              <div
                                className="faculty-signing-th"
                                key={subIndex}
                                style={{ width: "100%" }}
                              >
                                {subcat}
                              </div>
                            ))}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <Form.List name="rows">
                        {(fields) =>
                          fields.map(({ key, name, ...restField }, index) => {
                            const targetData =
                              targetAchievementData &&
                              targetAchievementData?.find(
                                (row: any) =>
                                  row.branch_id === branchOptions[index]?.id
                              );

                            return (
                              <TargetRaw
                                key={key}
                                index={index}
                                name={name}
                                branchOptions={
                                  branchOptions[index]?.code || "N/A"
                                }
                                months={months}
                                subcategories={subcategories}
                                targetAchievementData={targetData}
                                restField={restField}
                              />
                            );
                          })
                        }
                      </Form.List>
                    </tbody>
                  </table>
                </div>
                <div className="text-align-right gx-mt-2">
                  <Form.Item>
                    {ability.can(
                      Common.Actions.CAN_ADD,
                      Common.Modules.MASTER.MASTER_BRANCH_WISE_ADMISSION_TARGET
                    ) && (
                      <Button
                        className="btn-submit"
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </Card>
            </Form>
          </Skeleton>
        )
      )}
    </>
  );
};

export default AdmissionTarget;
