import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Col, Modal, Row, Skeleton, Tooltip, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import { ICDFeedBackRow } from "src/services/studentList/studentList.model";
import { AppDispatch } from "src/state/app.model";
import { bufferURLEncode, showTooltip } from "src/utils/helperFunction";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TableComponent from "src/components/DataTable";
import StudentProfileModel from "src/components/StudentProfileModel";
import { useAppSelector } from "src/state/app.hooks";
import { Can } from "src/ability/can";
import {
  FilterFilled,
  FileTextOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import CDGradeFilter from "./CDGradeFilter";
import { searchZoneData } from "src/state/zone/zone.action";
import { Common, InfoIconColor, ZoneType } from "src/utils/constants/constant";
import { userSelector } from "src/state/users/user.reducer";
import {
  downloadCsvCDGradeStudentHistory,
  searchCDGradeStudentHistory,
} from "src/state/report/report.action";
import {
  clearRemoveMessage,
  reportSelector,
} from "src/state/report/report.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { searchUserBatchData } from "src/state/userBatch/userBatch.action";
import { searchUserData } from "src/state/users/user.action";
import moment from "moment";
import { searchBatchData } from "src/state/batch/batch.action";

const CDGradeHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [selectedGradeStudentList, setSelectedGradeStudentList] =
    useState<ICDFeedBackRow>();
  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  const storageID: string | null = localStorage.getItem("myStorageID");
  const { userData } = useAppSelector(userSelector);
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const navigate = useNavigate();
  const location = useLocation();
  const backURL = bufferURLEncode(`${location.pathname}${location.search}`);
  const reportState = useAppSelector(reportSelector);
  const cdGradeHistoryState = reportState?.cdGradeHistoryData?.data;

  const info = (value: string) => {
    Modal.info({
      title: "Sub Topic",
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };

  useEffect(() => {
    if (reportState.cdGradeCsvHistoryData.message) {
      if (reportState.cdGradeCsvHistoryData.hasErrors) {
        message.error(reportState.cdGradeCsvHistoryData.message);
      } else {
        message.success(reportState.cdGradeCsvHistoryData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [reportState.cdGradeCsvHistoryData.message]);

  const handleExpensesCsv = () => {
    let data = Object.fromEntries(new URLSearchParams(searchParams));
    const branchIdsArray = [];
    const zoneIdsArray = [];

    if (data.branchIds) {
      branchIdsArray.push(
        ...data.branchIds.split(",").map((id) => id.replace(/'/g, ""))
      );
    }
    if (data.zoneIds) {
      zoneIdsArray.push(
        ...data.zoneIds.split(",").map((id) => id.replace(/'/g, ""))
      );
    }

    const payload: {
      branchIds: string[];
      zoneIds: string[];
      [key: string]: string[] | string;
    } = {
      branchIds: branchIdsArray,
      zoneIds: zoneIdsArray,
    };

    for (const key in data) {
      if (key !== "branchIds" && key !== "zoneIds") {
        if (Array.isArray(data[key])) {
          payload[key] = data[key];
        } else {
          payload[key] = data[key];
        }
      }
    }

    dispatch(downloadCsvCDGradeStudentHistory(payload));
  };
  const CDGradeColumns: ColumnsType<[]> = [
    {
      title: "No.",
      dataIndex: "id",
      className: "no",
      sorter: false,
      align: "center",
      width: "4%",
      render: (text, record, index) => (
        <>{(page - 1) * Number(searchParams?.get("take")) + index + 1}</>
      ),
    },
    {
      title: "GR ID",
      className: "admission.gr_id",
      render: (record) => {
        return (
          <div
            className="gx-text-danger"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedGradeStudentList(record);
              setOpenModel(true);
            }}
          >
            {record.admission.gr_id}
          </div>
        );
      },
    },
    {
      title: "Student Name",
      dataIndex: "studentName",
      className: "studentName",
      render: (record) => {
        return showTooltip(record?.toUpperCase(), 30);
      },
    },
    {
      title: "Batch Name",
      className: "batchSingingSheet.batch.name",
      render: (record) => {
        return (
          <>
            <div
              className="gx-text-danger"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (record?.id) {
                  navigate(
                    `/batch/${record?.batchSingingSheet?.batch_id}/faculty?r=${backURL}`
                  );
                }
              }}
            >
              <div>
                {record?.batchSingingSheet?.batch?.name}{" "}
                <Tooltip
                  placement="top"
                  title={record?.batchSingingSheet?.batch?.name}
                >
                  <InfoCircleOutlined
                    style={{
                      color: InfoIconColor.RED,
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <small className="faculty-name">
              ({record?.batchSingingSheet?.batch?.subcourse?.name})
            </small>
          </>
        );
      },
    },
    {
      title: "Topic/Subtopic Name",
      dataIndex: "batchSingingSheet",
      className: "",
      render: (record) => {
        return (
          <>
            <span>{record?.parent?.name}</span>
            <br />
            <div className="view-remarks">
              <small className="gx-mr-1 gx-mb-1 subtopic-text faculty-name">
                <span
                  dangerouslySetInnerHTML={{ __html: record?.description }}
                />
              </small>
              {record.description.length > 60 && (
                <Button type="primary" onClick={() => info(record.description)}>
                  More
                </Button>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Actual Date",
      dataIndex: "batchFacultyAttendance",
      className: "",
      render: (record) => {
        return <span>{moment(record.actual_date).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const dataConvertFromSearchParm = () => {
    let data = {};
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        setSearchParams("");
        data = "";
      } else if (key === "zoneIds") {
        Object.assign(data, {
          ["zoneIds"]: value.split(","),
        });
      } else if (key === "branchIds") {
        Object.assign(data, {
          ["branchIds"]: value.split(","),
        });
      } else if (key === "start_date") {
        Object.assign(data, {
          ["start_date"]: moment(value).format("YYYY-MM-DD"),
        });
      } else if (key === "end_date") {
        Object.assign(data, {
          ["end_date"]: moment(value).format("YYYY-MM-DD"),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }

    return data;
  };

  const cdGradeHistoryData = useMemo(() => {
    const updatedCDGradeHistoryData = cdGradeHistoryState?.rows?.map(
      (student: ICDFeedBackRow) => ({
        ...student,
        studentName: `${student.admission.first_name} ${student.admission.middle_name} ${student.admission.last_name}`,
      })
    );
    return updatedCDGradeHistoryData;
  }, [cdGradeHistoryState?.rows]);

  useEffect(() => {
    dispatch(searchCDGradeStudentHistory(dataConvertFromSearchParm()))?.then(
      (res) => {
        if (res?.payload) {
          setLoading(false);
        }
      }
    );
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        parent_id: currentUserZone?.id,
        type: ZoneType.PUBLIC,
        isFilter: true
      })
    );
    
    if (currentUserZone?.type !== ZoneType.PRIVATE) {
      dispatch(
        searchBranchData({
          noLimit: true,
          orderBy: "name",
          order: "ASC",
          isZoneOnly: true,
        })
      );
    }

    dispatch(
      searchUserData({ noLimit: true, orderBy: "first_name", order: "ASC" })
    );

    dispatch(searchBatchData({ noLimit: true }));
  }, []);

  useEffect(() => {
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [cdGradeHistoryState?.rows]);

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take"
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  return (
    <div className="rnw-main-content">
      <Skeleton active loading={loading} avatar>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xl={16}>
            <h2 className="rnw-page-title">
              <ContainerHeader title="CD Grade History" />
            </h2>
          </Col>

          <Col xxl={8} className="text-align-right">
            <Can
              I={Common.Actions.CAN_EXPORT_DATA}
              a={Common.Modules.REPORT.CD_GRADE_HISTORY}
            >
              <Button
                icon={<FileTextOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title:
                      "Are you sure want to download CD grade history csv file?",
                    okText: "Yes",
                    cancelText: "No",
                    icon: <DownloadOutlined />,
                    onOk() {
                      handleExpensesCsv();
                    },
                  });
                }}
              >
                CSV
              </Button>
            </Can>
            <Button
              icon={<FilterFilled />}
              onClick={() => setFilterDrawer(true)}
            >
              Filter
              <span>
                <Badge count={count}></Badge>
              </span>
            </Button>
          </Col>
        </Row>
        <Card className="rnw-card table-card cheque-amount gx-mb-0">
          <TableComponent
            rowKey={"AS"}
            columns={CDGradeColumns}
            dataSource={cdGradeHistoryData || []}
            loading={loading}
            meta={cdGradeHistoryState?.meta}
          />
        </Card>

        {filterDrawer && (
          <Row
            align="middle"
            justify="space-between"
            gutter={24}
            className="mb-20 gx-d-none gx-d-xl-flex"
          >
            <CDGradeFilter
              setFilterDrawer={setFilterDrawer}
              filterDrawer={filterDrawer}
            />
          </Row>
        )}
        {openModel && (
          <StudentProfileModel
            openModel={openModel}
            setOpenModel={(data) => setOpenModel(data)}
            modelData={{
              ...selectedGradeStudentList?.admission,
              father_name: selectedGradeStudentList?.admission?.father_name,
              profilePhoto:
                selectedGradeStudentList?.admission?.admission_documents[0]
                  .photos,
              studentName: selectedGradeStudentList?.studentName,
              batch_name:
                selectedGradeStudentList?.batchSingingSheet?.batch?.name,
              admission_id: selectedGradeStudentList?.admission_id,
            }}
          />
        )}
      </Skeleton>
    </div>
  );
};

export default CDGradeHistory;
