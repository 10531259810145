import { createAsyncThunk } from "@reduxjs/toolkit";
import reportService from "src/services/report/report.service";

export const getCompanyVisitReportData = createAsyncThunk(
  "report/getCompanyVisitReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getCompanyVisitReportData(data);
  }
);

export const getDegradeAndCancelRemarkData = createAsyncThunk(
  "report/getDegradeAndCancelRemarkData",
  async (data: URLSearchParams | {}) => {
    return reportService.getDegradeAndCancelRemarkData(data);
  }
);

export const getActiveStudentList = createAsyncThunk(
  "report/getActiveStudentList",
  async (data: URLSearchParams | {}) => {
    return reportService.getActiveStudentList(data);
  }
);

export const sendBranchActiveStudentListCsv = createAsyncThunk(
  "report/sendBranchActiveStudentListCsv",
  async (data: URLSearchParams | {}) => {
    return reportService.sendBranchActiveStudentListCsv(data);
  }
);

export const sendDegradeCancellationCsvFile = createAsyncThunk(
  "report/sendDegradeCancellationCsvFile",
  async (data: URLSearchParams | {}) => {
    return reportService.sendDegradeCancellationCsvFile(data);
  }
);

export const getExpertSessionsReportData = createAsyncThunk(
  "report/getExpertSessionsReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getExpertSessionsReportData(data);
  }
);
export const getStudentsProjectAnalysisReportData = createAsyncThunk(
  "report/getStudentsProjectAnalysisReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getStudentsProjectAnalysisReportData(data);
  }
);
export const getStudentsTopicAnalysisReportData = createAsyncThunk(
  "report/getStudentsTopicAnalysisReportData",
  async (data: URLSearchParams | {}) => {
    return reportService.getStudentsTopicAnalysisReportData(data);
  }
);

export const getLabWiseStudentData = createAsyncThunk(
  "report/getLabWiseStudentData",
  async (data: URLSearchParams | {}) => {
    return reportService.getLabWiseStudentData(data);
  }
);

export const getLabWiseBranchUtilizationData = createAsyncThunk(
  "report/getLabWiseBranchUtilizationData",
  async (data: URLSearchParams | {}) => {
    return reportService.getLabWiseBranchUtilizationData(data);
  }
);

export const getBranchWiseAllLabReport = createAsyncThunk(
  "report/getBranchWiseAllLabReport",
  async (data: URLSearchParams | {}) => {
    return reportService.getBranchWiseAllLabReport(data);
  }
);

export const branchWiseHoldStudentReport = createAsyncThunk(
  "report/branchWiseHoldStudentReport",
  async (data: URLSearchParams | {}) => {
    return reportService.branchWiseHoldStudentReport(data);
  }
);

export const removeSlotById = createAsyncThunk(
  "report/removeSlotById",
  async (id: number) => {
    return reportService.removeSlotById(id);
  }
);

// cd grade history
export const searchCDGradeStudentHistory = createAsyncThunk(
  "report/searchCDGradeStudentHistory",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.searchCDGradeStudentHistory(data);
  }
);

// cd grade history csv download
export const downloadCsvCDGradeStudentHistory = createAsyncThunk(
  "report/downloadCsvCDGradeStudentHistory",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.downloadCsvCDGradeStudentHistory(data);
  }
);

// searchCounsellorData
export const searchCounsellorData = createAsyncThunk(
  "report/searchCounsellorData",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.searchCounsellorData(data);
  }
);

export const searchStudentExamMarks = createAsyncThunk(
  "report/searchStudentExamMarks",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.searchStudentExamMarks(data);
  }
);

export const examMarkReportCsvEmail = createAsyncThunk(
  "report/examMarkReportCsvEmail",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.examMarkReportCsvEmail(data);
  }
);

export const monthWiseAdmissionCounts = createAsyncThunk(
  "report/admissions-count",
  async (data: URLSearchParams | {}) => {
    return reportService.getMonthWiseAdmissionCounts(data);
  }
);

export const monthWiseBranchCollection = createAsyncThunk(
  "report/collection-report",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.monthwiseBranchCollection(data);
  }
);

export const monthWiseWalkInDone = createAsyncThunk(
  "report/month-wise-walk-in-done",
  async (data: URLSearchParams | undefined | {}) => {
    return reportService.monthWiseWalkInDone(data);
  }
);
