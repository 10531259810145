import { config } from "src/utils";
import { IApiResponse } from "../../utils/models/common";
import axios from "axios";
import {
  IAttendanceDataByDateAndGrIdWise,
  IBatchWiseStudentAttendanceData,
  IStudentAttendanceDetails,
  IStudentAttendanceDetailsByGrId,
  ISystemTrackerData,
  ISystemTrackerDetails,
} from "./systemTracker.model";
import request from "src/lib/axios/request";

class SystemTrackerService {
  ENDPOINT = config.baseApiSystemTracker;

  public getSystemTrackerDetails = async (
    data: ISystemTrackerDetails
  ): Promise<IApiResponse<ISystemTrackerData[]>> => {
    const url = `${this.ENDPOINT}/get-pc-details`;
    try {
      const response = await axios.request({
        url,
        method: "GET",
        params: data,
        headers: {
          Authorization: `${process.env.REACT_APP_SYATEN_TRACKER_TOKEN}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data found");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        throw new Error("Unexpected error occurred");
      }
    }
  };

  public getStudentAttendanceDataBatchWise = async (
    data: IStudentAttendanceDetails
  ): Promise<IApiResponse<IBatchWiseStudentAttendanceData[]>> => {
    const url = `${this.ENDPOINT}/get-batch-wise-student-attendance`;
    try {
      const response = await request({
        url,
        method: "GET",
        params: data,
      });

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data found");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        throw new Error("Unexpected error occurred");
      }
    }
  };

  public getAttendanceDataByDateAndGrIdWise = async (
    data: IStudentAttendanceDetailsByGrId
  ): Promise<IApiResponse<IAttendanceDataByDateAndGrIdWise[]>> => {
    const url = `${this.ENDPOINT}/get-student-wise-attendance`;
    try {
      const response = await request({
        url,
        method: "GET",
        params: data,
      });

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data found");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        throw new Error("Unexpected error occurred");
      }
    }
  };
}

export default new SystemTrackerService();
