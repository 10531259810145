import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  Col,
  DatePicker,
  Row,
  Skeleton,
  Table,
  Typography,
  Alert,
} from "antd";
import moment from "moment";
import ContainerHeader from "src/components/ContainerHeader";
import { getAllTargetAchievementReportData } from "src/state/targetAchievement/targetAchievement.action";
import { useAppSelector } from "src/state/app.hooks";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { AppDispatch } from "src/state/app.model";
import { useDispatch } from "react-redux";
import { targetAdmissionSelector } from "src/state/targetAchievement/targetAchievement.reducer";
import { ColumnsType } from "antd/lib/table";
import { TargetReportData } from "src/services/targetAchievement/targetAchievement.model";
import {
  RatioColourType,
  TargetCategoryType,
} from "src/utils/constants/constant";
import { useSearchParams } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const CollectionTargetAchievementReport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const branchState = useAppSelector(branchSelector);
  const targetAchievementState = useAppSelector(targetAdmissionSelector);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] = useState<
    [moment.Moment, moment.Moment] | null
  >();

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isReport: false,
        isAllBranch: true,
      })
    ).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const startDateParam = searchParams.get("start_date");
    const endDateParam = searchParams.get("end_date");

    if (startDateParam && endDateParam) {
      setSelectedRange([
        moment(startDateParam, "YYYY-MM"),
        moment(endDateParam, "YYYY-MM"),
      ]);
    } else {
      setSelectedRange(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedRange) {
      setLoading(true);
      const [start_date, end_date] = selectedRange;
      const payload = {
        start_date: start_date.format("YYYY-MM"),
        end_date: end_date.format("YYYY-MM"),
        noLimit: true,
        type: TargetCategoryType.COLLECTIONS,
        isReport: false,
        orderBy: "name",
        order: "ASC",
      };
      dispatch(getAllTargetAchievementReportData(payload)).finally(() =>
        setLoading(false)
      );
    } else {
      setLoading(false);
    }
  }, [dispatch, selectedRange]);

  const handleRangeChange = (
    dates: [moment.Moment | null, moment.Moment | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setSelectedRange([dates[0], dates[1]]);
      setSearchParams({
        start_date: dates[0].format("YYYY-MM"),
        end_date: dates[1].format("YYYY-MM"),
      });
    } else {
      setSelectedRange(null);
      setSearchParams({});
      dispatch(getAllTargetAchievementReportData([]));
    }
  };

  const branchOptions = branchState?.branchesData?.data?.rows || [];
  const targetAchievementData =
    targetAchievementState.getAllTargetAchievementReportData.data || [];

  const columns: ColumnsType<TargetReportData> = useMemo(
    () => [
      {
        title: "No.",
        dataIndex: "index",
        key: "index",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Branch Code",
        dataIndex: "branchCode",
        key: "branchCode",
        render: (_, record) => {
          const branch = branchOptions.find((b) => b.id === record.branch_id);
          return branch ? branch.code : "-";
        },
      },
      {
        title: "Target",
        dataIndex: "total_value",
        key: "total_value",
        render: (data: number) => data || 0,
      },
      {
        title: "Achievement",
        dataIndex: "total_amount",
        key: "total_amount",
        render: (data: number) => data || 0,
      },
      {
        title: "Ratio",
        dataIndex: "ratio",
        key: "ratio",
        render: (_, record: TargetReportData) => {
          const total_value = record.total_value || 0;
          const total_amount = record.total_amount || 0;
          const ratio =
            total_value === 0
              ? "0.00%"
              : ((total_amount / total_value) * 100).toFixed(2) + "%";

          const getBackgroundColor = (value: string): string => {
            const ratioValue = parseFloat(value || "0.00%");
            if (ratioValue > 85) return RatioColourType.GREEN;
            if (ratioValue >= 60 && ratioValue <= 85)
              return RatioColourType.YELLOW;
            return RatioColourType.RED;
          };
          const backgroundColor = getBackgroundColor(ratio);
          const hasBackground = backgroundColor !== "";

          return (
            <div
              className={`ratio-cell ${
                hasBackground ? "with-background" : "transparent"
              }`}
              style={{
                backgroundColor: hasBackground
                  ? backgroundColor
                  : "transparent",
              }}
            >
              {ratio}
            </div>
          );
        },
      },
    ],
    [branchOptions]
  );

  const calculateTotal = (key: "total_value" | "total_amount") => {
    return targetAchievementData.reduce(
      (total, record) => total + (record[key] || 0),
      0
    );
  };

  const calculateTotalRatio = () => {
    const totalTarget = calculateTotal("total_value");
    const totalAchievement = calculateTotal("total_amount");
    if (totalTarget === 0) return "0.00%";
    return ((totalAchievement / totalTarget) * 100).toFixed(2) + "%";
  };

  let message = "";
  if (!selectedRange) {
    message = "Apply filters to retrieve the data you are looking for.";
  } else if (selectedRange && targetAchievementData.length === 0) {
    message =
      "No targets found! Please add a Collection Target to display the data.";
  }

  return (
    <div className="rnw-main-content">
      <Row align="middle" justify="space-between" gutter={24} className="mb-20">
        <Col xxl={12}>
          <ContainerHeader title="Collection Target vs Achievement Report" />
        </Col>
        <Col xl={8} className="text-align-right">
          <RangePicker
            picker="month"
            onChange={handleRangeChange}
            value={selectedRange}
            format="MM-YYYY"
          />
        </Col>
      </Row>
      <Card className="rnw-card view-student">
        <Skeleton loading={loading} active>
          {message ? (
            <Alert
              message={message}
              type={message.includes("Apply") ? "info" : "warning"}
              showIcon
            />
          ) : (
            selectedRange &&
            targetAchievementData.length > 0 && (
              <Table
                className="table-striped"
                columns={columns}
                dataSource={targetAchievementData}
                rowKey="branch_id"
                bordered
                pagination={false}
                summary={(pageData) => {
                  const totalTarget = calculateTotal("total_value");
                  const totalAchievement = calculateTotal("total_amount");
                  const totalRatio = calculateTotalRatio();

                  return (
                    <Table.Summary.Row className="sticky-bottom">
                      <Table.Summary.Cell index={0}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text strong></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text strong>{totalTarget.toLocaleString()}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text strong>{totalAchievement.toLocaleString()}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} align="center">
                        <Text strong>{totalRatio}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            )
          )}
        </Skeleton>
      </Card>
    </div>
  );
};

export default CollectionTargetAchievementReport;
