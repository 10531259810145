import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Badge,
  Form,
  Drawer,
  Select,
  Input,
  DatePicker,
  message,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import {
  FilterFilled,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import TableComponent from "src/components/DataTable";
import { AppDispatch } from "src/state/app.model";
import type { ColumnsType } from "antd/es/table";
import { getExpertSessionsReportData } from "src/state/report/report.action";
import { useAppSelector } from "src/state/app.hooks";
import {
  clearRemoveMessage,
  reportSelector,
} from "src/state/report/report.reducer";
import { dateFormate, timeFormate, trimObject } from "src/utils/helperFunction";
import { IExpertSessionsDetails } from "src/services/report/report.model";
import FloatLabel from "src/components/Form/FloatLabel";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { departmentSelector } from "src/state/department/department.reducer";
import { searchDepartmentData } from "src/state/department/department.action";
import moment from "moment";
import {
  ExpertSessionReport,
  IExpertSessionsReport,
} from "./ExpertSessionsReport.model";
import { CSVLink } from "react-csv";
import { ZoneType } from "src/utils/constants/constant";
import { userSelector } from "src/state/users/user.reducer";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { searchZoneData } from "src/state/zone/zone.action";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ExpesrtSessionReport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const branchState = useAppSelector(branchSelector);
  const departmentState = useAppSelector(departmentSelector);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const reportState = useAppSelector(reportSelector);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const { userData } = useAppSelector(userSelector);
  const [formValues, setFormValues] = useState({});
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const [csvDataShow, setCsvDataShow] = useState(false);
  const [csvData, setCsvData] = useState<ExpertSessionReport[]>([]);
  const [modelOpen, setModelOpen] = useState(false);
  const zoneState = useAppSelector(zoneSelector);

  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );

  const dataConvertFromSearchParm = () => {
    let data = {};
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        // setSearchParams("");
        data = "";
      } else if (key === "branch_id") {
        Object.assign(data, {
          ["branch_id"]: value.split(",").map((branch) => Number(branch)),
        });
      } else if (key === "start_date") {
        Object.assign(data, {
          ["start_date"]: moment(value).format("YYYY-MM-DD"),
        });
      } else if (key === "end_date") {
        Object.assign(data, {
          ["end_date"]: moment(value).format("YYYY-MM-DD"),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    return data;
  };

  useEffect(() => {
    const data = {
      ...setFormValues,
      ...dataConvertFromSearchParm(),
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (key === "branch_id") {
        Object.assign(data, {
          branch_id: value.split(",").map((branch) => Number(branch)),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    const urlData = Object.fromEntries(new URLSearchParams(searchParams));
    if (urlData && urlData?.start_date && urlData?.end_date) {
      Object.assign(data, {
        ["date"]: [moment(urlData?.start_date), moment(urlData?.end_date)],
      });
    }

    setFormValues(data);
  }, [searchParams]);

  useEffect(() => {
    if (reportState.expertSessionsReportData.message !== "") {
      if (reportState.expertSessionsReportData.hasErrors) {
        message.error(reportState.expertSessionsReportData.message);
      }
    }
    dispatch(clearRemoveMessage());
  }, [reportState.expertSessionsReportData.message]);

  useEffect(() => {
    dispatch(getExpertSessionsReportData(dataConvertFromSearchParm())).then(
      () => {
        setLoading(false);
      }
    );
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
          Number(searchParams.get("take")) +
          1
          : 1
      );
    }
  }, [reportState.expertSessionsReportData.data]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  const onFinish = (values: IExpertSessionsReport) => {
    let data = Object.fromEntries(
      new URLSearchParams(trimObject(searchParams))
    );
    if (values.date) {
      let date = {
        start_date: moment(values.date[0]).format("YYYY-MM-DD"),
        end_date: moment(values.date[1]).format("YYYY-MM-DD"),
      };
      data = date;
    }

    values = { ...data, ...values };
    const { date, ...rest } = values;
    const newData = Object.assign(rest);
    Object.keys(newData).forEach(
      (key) =>
        (newData[key] === undefined || newData[key].length <= 0) &&
        delete newData[key]
    );
    const queryString = Object.entries(trimObject(newData))
      .filter(([key, values]) => values !== undefined && values !== "")
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");
    setSearchParams(queryString);
    setFilterModalOpen(false);
  };

  useEffect(() => {
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );
    dispatch(
      searchDepartmentData({
        isFilter: true,
        noLimit: true,
        orderBy: "name",
        order: "ASC",
      })
    );
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        type: ZoneType.PUBLIC,
        parent_id: currentUserZone?.id,
      })
    );
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        type: ZoneType.PUBLIC,
        parent_id: currentUserZone?.id,
        isFilter: true,
      })
    );
  }, []);

  const onReset = () => {
    setSearchParams({});
    setFormValues(" ");
    form.resetFields();
    setCount(0);
  };
  const zoneData = Form.useWatch("zone", form);
  const branchData =
    branchState.branchesData.data.rows
      .filter((branch) =>
        zoneData ? zoneData == branch.zone?.parent_id[0].id : branch
      )
      .filter((branch) => branch.status === true) || [];
  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  const onCancel = () => {
    setShow(false);
    setFilterModalOpen(false);
  };

  const expertSessionReportColumns: ColumnsType = [
    {
      title: "No.",
      width: "5%",
      align: "center",
      dataIndex: "no",
      className: "no",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      className: "branch",
      key: "code",
      width: "5%",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "venue_date",
      className: "venue_date",
      key: "venue_date",
      width: "30%",
    },
    {
      title: "Time",
      dataIndex: "venue_time",
      className: "venue_time",
      key: "venue_time",
      width: "30%",
    },
    {
      title: "Expert Name",
      dataIndex: "expert_name",
      className: "expert_name",
      key: "expert_name",
      width: "30%",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      className: "subject",
      key: "subject",
      width: "30%",
    },
    {
      title: "Sector",
      dataIndex: "department",
      className: "department",
      key: "department",
      width: "30%",
    },
    {
      title: "Total Student",
      dataIndex: "total_student",
      className: "total_student",
      key: "total_student",
      width: "30%",
    },
    {
      title: "No Of Present Student",
      dataIndex: "no_of_present",
      className: "no_of_present",
      key: "no_of_present",
      width: "30%",
    },
    {
      title: "No Of Absent Student",
      dataIndex: "no_of_absent",
      className: "no_of_absent",
      key: "no_of_absent",
      width: "30%",
    },
  ];

  const converExpertSession = (esReportData: IExpertSessionsDetails[]) => {
    return esReportData.map((data: IExpertSessionsDetails, index: number) => {
      const no = (page - 1) * Number(searchParams.get("take")) + index + 1;
      const date = dateFormate(data.venue_datetime);
      const time = timeFormate(data.venue_datetime);
      const department =
        data.admission_recurings.length > 0
          ? data.admission_recurings[0].admission.department.name
          : "";
      const totalStudentCount =
        data.admission_recurings.length > 0
          ? data.admission_recurings.length
          : 0;
      const presentCount = data.admission_recurings.filter(
        (student) => student.is_present === true
      ).length;
      const noOfPresentCount = presentCount > 0 ? presentCount : 0;
      const absentCount = data.admission_recurings.filter(
        (student) => student.is_present === false
      ).length;
      const noOfAbsentCount = absentCount > 0 ? absentCount : 0;

      return {
        no: no,
        branch: data.branch.code,
        venue_date: date,
        venue_time: time,
        expert_name: data.expert_name,
        subject: data.subject,
        department: department,
        total_student: totalStudentCount,
        no_of_present: noOfPresentCount,
        no_of_absent: noOfAbsentCount,
      };
    });
  };
  const expertSessionReportData = converExpertSession(
    reportState.expertSessionsReportData.data.rows
  );

  useEffect(() => {
    if (csvDataShow) {
      dispatch(
        getExpertSessionsReportData({
          noLimit: true,
          ...dataConvertFromSearchParm(),
        })
      ).then((res: any) => {
        const expertSessionReport = converExpertSession(res.payload.data.rows);

        setCsvData(expertSessionReport);
        setCsvDataShow(false);
      });
    } else {
      dispatch(getExpertSessionsReportData(dataConvertFromSearchParm())).then(
        () => {
          setLoading(false);
          dispatch(clearRemoveMessage());
        }
      );
    }
  }, [csvDataShow]);
  return (
    <>
      <div className="rnw-main-content">
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xxl={12}>
            <ContainerHeader title="Expert Session Report" />
          </Col>

          <Col xl={8} className="text-align-right">
            <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
              <Button
                icon={<FileTextOutlined />}
                onClick={() => {
                  setModelOpen(true);
                  setCsvDataShow(true);
                }}
              >
                CSV
              </Button>

              <Button
                icon={<FilterFilled />}
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
                <span>
                  <Badge count={count}></Badge>
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          open={modelOpen}
          className="error-modal"
          onCancel={() => setModelOpen(false)}
          footer={[
            <Button key="back" onClick={() => setModelOpen(false)}>
              NO
            </Button>,
            <CSVLink
              className="gx-mr-2"
              filename={"ExpressionReport.csv"}
              data={csvData || []}
              headers={expertSessionReportColumns.map((colName) => {
                return {
                  label: colName.title as string,
                  key: colName.className as string,
                };
              })}
            >
              <Button
                onClick={() =>
                  setTimeout(() => {
                    setModelOpen(false);
                  }, 1000)
                }
              >
                Yes
              </Button>
            </CSVLink>,
          ]}
        >
          <p className="report-download-error">
            <DownloadOutlined />
          </p>
          <p className="report-download-error-text">
            Are you sure want to download Expert Session CSV file?
          </p>
        </Modal>
        <Row>
          <Col span={24}>
            <div className="filter" style={{ height: "auto" }}>
              <Drawer
                className="filter-drawer"
                title="Expert Session Report Filter"
                width="1000"
                visible={filterModalOpen}
                onClose={() => {
                  setFilterModalOpen(false);
                }}
                footer={[
                  <div className="gx-d-flex gx-justify-content-center">
                    <Button
                      className="cancel-filter gx-mr-0"
                      key="back"
                      onClick={onCancel}
                    >
                      <span className="gx-d-none gx-d-sm-block">Cancel</span>
                      <i className="fa fa-close gx-d-sm-none"></i>
                    </Button>
                    <Button
                      className="btn-apply-filter gx-mx-2"
                      key="submit"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      form="myForm"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className="reset-filter"
                      type="default"
                      htmlType="reset"
                      form="myForm"
                    >
                      <span className="gx-d-none gx-d-sm-block">Reset</span>
                      <i className="fa fa-refresh gx-d-sm-none"></i>
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  id="myForm"
                  onReset={onReset}
                  onFinish={onFinish}
                  form={form}
                  initialValues={formValues}
                >
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FloatLabel
                        label="Expert Name"
                        placeholder="Enter Expert Name"
                        name="expert_name"
                      >
                        <Form.Item name="expert_name">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    {currentUserZone?.type !== ZoneType.PRIVATE &&
                      zoneState.zonesData.data.rows.length > 0 && (
                        <Col xs={24}>
                          <FloatLabel
                            label="Zone"
                            placeholder="Select Zone"
                            name="zone"
                          >
                            <Form.Item name="zone">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                showArrow
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.children?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase().trim())
                                }
                              >
                                {zoneState.zonesData.data.rows

                                  .filter(
                                    (zone) =>
                                      zone?.id === currentUserZone?.id ||
                                      zone?.parent_id === currentUserZone?.id
                                  )
                                  .filter((z) => z.status === true)
                                  .map((zone) => (
                                    <Option value={zone.id.toString()}>
                                      {zone.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      )}

                    {currentUserZone?.type !== ZoneType.PRIVATE && (
                      <Col xs={24}>
                        <FloatLabel
                          label="Branch Code"
                          placeholder="Select Branch Code"
                          name="branch_id"
                        >
                          <Form.Item name="branch_id">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              mode="multiple"
                              showArrow
                              allowClear
                              showSearch
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                            >
                              {branchData.map((branch) => (
                                <Option value={branch.id}>
                                  {branch.code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    )}

                    <Col xs={24}>
                      <FloatLabel
                        label="Sector"
                        placeholder="Select Sector"
                        name="department_id"
                      >
                        <Form.Item name="department_id">
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentNode
                            }
                            size="large"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase().trim())
                            }
                          >
                            {departmentState.departmentsData.data.rows.map(
                              (depart) => (
                                <Option
                                  key={depart.id}
                                  value={depart.id.toString()}
                                >
                                  {depart.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <Form.Item name="date">
                        <RangePicker
                          style={{ width: "100%" }}
                          getPopupContainer={(trigger) => trigger}
                          size="large"
                          name="date"
                          format={dateFormat}
                          ranges={{
                            Today: [moment(), moment()],
                            Week: [
                              moment().startOf("week"),
                              moment().endOf("week"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </div>

            <Card className="rnw-card view-student">
              <TableComponent
                columns={expertSessionReportColumns}
                dataSource={expertSessionReportData || []}
                meta={reportState.expertSessionsReportData.data.meta}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExpesrtSessionReport;
