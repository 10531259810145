import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IStatisticState } from "./statistic.model";
import { admissionReport, packageRevenueReport, totalCompletionReport, subCourseRevenueReport } from "./statistic.action";

export const initialState: IStatisticState = {
    statisticData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      totalAdmissions: [],
      completionAdmissions: [],
      cancelledAdmissions: [],
      terminatedAdmission: [],
      },
  },
  packageRevenueData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  admissionReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  subCourseRevenueData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.statisticData.message = "";
      state.packageRevenueData.message = "";
      state.admissionReportData.message = "";
      state.subCourseRevenueData.message = "";
    },
  },
  extraReducers: {
    [totalCompletionReport.pending.type]: (state) => {
      state.statisticData.loading = true;
    },
    [totalCompletionReport.fulfilled.type]: (state, action) => {
      state.statisticData.data = action.payload.data;
      state.statisticData.message = action.payload.message;
      state.statisticData.loading = false;
      state.statisticData.hasErrors = false;
    },
    [totalCompletionReport.rejected.type]: (state, action) => {
      state.statisticData.loading = false;
      state.statisticData.hasErrors = true;
      state.statisticData.message = action.error.message;
    },


    [packageRevenueReport.pending.type]: (state) => {
      state.packageRevenueData.loading = true;
    },
    [packageRevenueReport.fulfilled.type]: (state, action) => {
      state.packageRevenueData.data = action.payload.data;
      state.packageRevenueData.message = action.payload.message;
      state.packageRevenueData.loading = false;
      state.packageRevenueData.hasErrors = false;
    },
    [packageRevenueReport.rejected.type]: (state, action) => {
      state.packageRevenueData.loading = false;
      state.packageRevenueData.hasErrors = true;
      state.packageRevenueData.message = action.error.message;
    },

    [admissionReport.pending.type]: (state) => {
      state.admissionReportData.loading = true;
    },
    [admissionReport.fulfilled.type]: (state, action) => {
      state.admissionReportData.data = action.payload.data;
      state.admissionReportData.message = action.payload.message;
      state.admissionReportData.loading = false;
      state.admissionReportData.hasErrors = false;
    },
    [admissionReport.rejected.type]: (state, action) => {
      state.admissionReportData.loading = false;
      state.admissionReportData.hasErrors = true;
      state.admissionReportData.message = action.error.message;
    },
    
    // Sub Course Revenue
    [subCourseRevenueReport.pending.type]: (state) => {
      state.subCourseRevenueData.loading = true;
    },
    [subCourseRevenueReport.fulfilled.type]: (state, action) => {
      state.subCourseRevenueData.data = action.payload.data;
      state.subCourseRevenueData.message = action.payload.message;
      state.subCourseRevenueData.loading = false;
      state.subCourseRevenueData.hasErrors = false;
    },
    [subCourseRevenueReport.rejected.type]: (state, action) => {
      state.subCourseRevenueData.loading = false;
      state.subCourseRevenueData.hasErrors = true;
      state.subCourseRevenueData.message = action.error.message;
    },
  },
});

// A selector
export const statisticSelector = (state: RootState) => state.statistics;

export const { clearRemoveMessage } = statisticSlice.actions;

// The reducer
export default statisticSlice.reducer;
