import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  ICreateQuestionBank,
  IQuestionBankData,
  IUpdateQuestionBank,
} from "./subCourseQuestionBank.modal";

class SubCourseQuestionBankService {
  ENDPOINT = config.baseApiMasters + "/question-bank";

  public searchSubCourseQuestionBankData = async (
    data: any
  ): Promise<IApiResponse<IQuestionBankData>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}`;
    return request({
      url,
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  };

  public searchSubCourseAndBatchWiseQuestionBankData = async (
    data: any
  ): Promise<IApiResponse<IQuestionBankData>> => {
    const url = `${this.ENDPOINT}/${data.subcourse_id}/${data.batch_id}/${data.signingSheetId}`;
    return request({
      url,
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  };

  public createNewSubCourseQuestionBank = async (
    data: ICreateQuestionBank
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public removeSubCourseQuestionBankById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public updateSubCourseQuestionBankData = async (
    data: IUpdateQuestionBank
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
}

export default new SubCourseQuestionBankService();
