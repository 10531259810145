import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createAdmission,
  createNewRemark,
  getAdmissionRemarksData,
  searchAdmissionData,
  searchAdmissionCsvEmailSendData,
  updateAdmissionStatus,
  assignBatch,
  unAssignBatch,
  cancelAdmission,
  editAdmissionBasicDataById,
  courseInfo,
  admissionBranchTransfer,
  courseModificationById,
  getAdmissionDetailsById,
  getGrIdData,
  courseCompleted,
  updatePostalCommunication,
  updateParentsDetails,
  updateEducationAndProfessionDetails,
  updateDocuments,
  searchMissingAdmissionData,
  installmentModify,
  deleteAdmissionOtherDocumentById,
  searchUnAssignBatchAdmission,
  missingCRMDetails,
  concessionApplyUpdate,
  verifiyCRMRecord,
  crmLeadHistoryRecord,
  createBulkRemark,
  getCRMLeadEnquiries,
  pushCRMLeadEnquirie,
  getCRMCounsellor,
  concessionRemove,
  createPenalty,
  searchPenaltyData,
  autoFeedback,
  getCRMLead,
  autoUpdateCRMLead,
  getAppStundetFeedBackById,
  admissionRevision,
  performanceModificationById,
  getStudentUnpaidInstallment,
  searchConcessionData,
  getNsdcAdmissionData,
  searchSchoolCollageNames,
} from "./admission.action";

import { IAdmissionState } from "./admission.model";
import { boolean } from "yargs";

export const initialState: IAdmissionState = {
  admissionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  concessionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  searchAdmissionCsvEmailSendData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  admissionMissingData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  crmLeadEnquiries: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  pushedCrmLeadEnquirie: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  cancelAdmissionById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  createAdmission: {
    data: {
      id: 0,
      status: "",
    },
    loading: false,
    hasErrors: false,
    message: "",
  },
  editBasicDataById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  batchTransfer: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  statusUpdate: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  remarksData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  grIdData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  courseInfo: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  createRemark: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  bulkRemark: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  assignBatch: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  unAssignBatch: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  courseModifyById: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      id: 0,
      gr_id: 0,
      source_id: 0,
      student_id: 0,
      branch_id: 0,
      concession: false,
      concession_percentage: 0,
      department_id: 0,
      subdepartment_id: 0,
      package_id: 0,
      starting_course_id: 0,
      course_category: "",
      batch_id: 0,
      user_id: 0,
      enrollment_number: "",
      admission_number: 0,
      year: 0,
      aadhar_card_no: "",
      passport_no: "",
      is_nri: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      alternate_no: "",
      dob: "",
      gender: "",
      father_name: "",
      father_email: "",
      father_mobile_no: "",
      father_occupation: "",
      father_income: "",
      mother_name: "",
      mother_email: "",
      mother_mobile_no: "",
      mother_occupation: "",
      mother_income: "",
      tuation_fees: "",
      admission_amount: "",
      payable_amount: "",
      settlement_amount: "",
      discount_amount: "",
      grade: "",
      no_of_installment: 0,
      registration_fees: "",
      present_country: "",
      present_state: "",
      present_city: "",
      present_area: "",
      present_pin_code: "",
      present_flate_house_no: "",
      present_area_street: "",
      present_landmark: "",
      permanent_country: "",
      permanent_state: "",
      permanent_city: "",
      permanent_area: "",
      permanent_pin_code: "",
      permanent_area_street: "",
      permanent_landmark: "",
      school_collage_name: "",
      school_clg_country: "",
      school_clg_state: "",
      school_clg_city: "",
      school_clg_area: "",
      school_clg_lat: "",
      school_clg_long: "",
      school_clg_google_response: "",
      placement_status: "",
      send_to_father_email: false,
      send_to_father_sms: false,
      admission_type: "",
      status: "",
      start_date: "",
      end_date: "",
      comment: "",
      created_by: "",
      created_date: "",
      updated_by: "",
      updated_date: "",
      permanent_flate_house_no: "",
      send_to_sms: false,
      send_to_email: false,
      scl_clg_type: "",
      permanent_location: [],
      present_location: [],
      school_college_location: [],
      admission_installments: [],
      admissionPackageHistories: [
        {
          admission_id: 0,
          package_id: 0,
          package: {
            id: 0,
            name: "",
            code: "",
          },
        },
      ],
      admission_documents: [
        {
          id: 0,
          admission_id: 0,
          photos: "",
          aadhar_card: "",
          form: "",
          last_passing_marksheet: "",
        },
      ],
      admission_other_documents: [
        {
          id: 0,
          name: "",
          path: "",
        },
      ],
      branch: {
        id: 0,
        zone_id: 0,
        name: "",
        code: "",
        title: "",
        email: "",
        mobile_one: "",
        mobile_two: "",
        landline_no: "",
        website: "",
        pan_no: "",
        gst_no: "",
        status: false,
        branch_departments: [],
        zone: {
          id: 0,
          parent_id: [
            {
              id: 0,
              name: "",
              code: "",
            },
          ],
        },
        bank_name: "",
        account_holder_name: "",
        account_no: "",
        ifsc: "",
        account_type: "",
        logo: "",
        address: "",
        country: "",
        state: "",
        city: "",
        area: "",
        created_by: "",
        created_date: "",
        updated_by: "",
        updated_date: "",
        help_center_title: [],
        enrolment_no_sequence: 0,
        invoice_no_sequence: 0,
      },
      department: {
        id: 0,
        name: "",
        code: 0,
        status: false,
        branch_departments: [
          {
            branch_id: 0,
            branch: {
              id: 0,
              name: "",
              code: "",
              status: false,
            },
          },
        ],
        photos: false,
        tenthMarksheet: false,
        twelfthMarksheet: false,
        leavingCerty: false,
        adharCard: false,
        other: false,
      },
      admission_subcourses: [],
      package: {
        id: 0,
        department_id: 0,
        subdepartment_id: 0,
        name: "",
        code: "",
        career_course: false,
        upskill_course: false,
        total: 0,
        duration: 0,
        installment: 0,
        status: false,
        created_by: "",
        created_date: "",
        updated_by: "",
        updated_date: "",
        department: {
          id: 0,
          name: "",
          code: 0,
          status: false,
          branch_departments: [
            {
              branch_id: 0,
              branch: {
                id: 0,
                name: "",
                code: "",
                status: false,
              },
            },
          ],
          photos: false,
          tenthMarksheet: false,
          twelfthMarksheet: false,
          leavingCerty: false,
          adharCard: false,
          other: false,
        },
        subdepartment: {
          id: 0,
          department_id: 0,
          department: {
            id: 0,
            name: "",
            code: 0,
            status: false,
            branch_departments: [
              {
                branch_id: 0,
                branch: {
                  id: 0,
                  name: "",
                  code: "",
                  status: false,
                },
              },
            ],
            photos: false,
            tenthMarksheet: false,
            twelfthMarksheet: false,
            leavingCerty: false,
            adharCard: false,
            other: false,
          },
          name: "",
          code: 0,
          status: false,
          createdDate: "",
          updatedDate: "",
        },
        package_fees: [],
        package_subcourses: [],
        package_branches: [],
        package_branches_fees: [],
      },
      batch: 0,
      user: {
        first_name: undefined,
        last_name: undefined,
        email: "",
        phone: "",
        status: undefined,
        id: 0,
        user_roles: undefined,
        user: {
          first_name: undefined,
          last_name: undefined,
          email: "",
          phone: "",
          status: undefined,
          id: 0,
          user_roles: undefined,
        },
      },
      subdepartment: {
        id: 0,
        department_id: 0,
        department: {
          id: 0,
          name: "",
          code: 0,
          status: false,
          branch_departments: [
            {
              branch_id: 0,
              branch: {
                id: 0,
                name: "",
                code: "",
                status: false,
              },
            },
          ],
          photos: false,
          tenthMarksheet: false,
          twelfthMarksheet: false,
          leavingCerty: false,
          adharCard: false,
          other: false,
        },
        name: "",
        code: 0,
        status: false,
        createdDate: "",
        updatedDate: "",
      },
      student: {},
      permanent_google_response: null,
      permanent_lat: null,
      permanent_long: null,
      present_google_response: null,
      present_lat: null,
      present_long: null,
    },
  },

  courseCompleted: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  updatePostalCommunication: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  updateParentsDetails: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  educationAndProfessionDetails: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  updateDocuments: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  deleteAdmissionOtherDocumentById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  installmentModify: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  unAssignBatchAdmissionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  missingCRMData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },

  crmCounsellorData: {
    loading: false,
    hasErrors: false,
    message: "",
    result: [],
  },

  reverifiyCRMRecord: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  concessionApplyUpdate: {
    data: {
      id: 0,
      discount_amount: 0,
      admission_remarks: {
        remarks: "",
        labels: "",
        rating: "",
      },
    },
    loading: false,
    hasErrors: false,
    message: "",
  },

  admissionCrmHistoryData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },

  concessionRemove: {
    data: {
      id: 0,
      discount_amount: 0,
      admission_remarks: {
        remarks: "",
        labels: "",
        rating: "",
      },
    },
    loading: false,
    hasErrors: false,
    message: "",
  },
  createPenalty: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  penaltyData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  autoFeedback: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },

  getCRMLead: {
    loading: false,
    hasErrors: false,
    message: "",
    result: {
      phone: "",
      name: "",
      email: "",
      city: "",
      mx_reference_by: "",
      branch: "",
      lead_source: "",
      occupation: "",
      study: "",
      school_or_college: "",
      student_age: "",
      address_1: "",
      address_2: "",
      country: "",
      zip: "",
      who_comes_with_you_for_todays_counselling_session: "",
      fathers_occupation: "",
      date_of_birth: 0,
      gr_id: "",
      father_name: "",
      mother_name: "",
      mother_number: "",
      state: "",
      father_number: "",
      mx_admission_status: "",
      mx_payment_status: "",
      admission_course: "",
      mx_department: "",
      alternate_phone: "",
      mx_erp_source: "",
      gender: "",
      after_discount_fees: "",
      admission_done_total_fees: "",
      employeeid: "",
    },
  },

  autoUpdateCRMLead: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  appStundetFeedBackData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },

  admissionRevision: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  performanceModifyById: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  unpaidInstallmentListData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      id: 0,
      status: "",
      gr_id: 0,
      admission_installments: [],
    },
  },

  getNsdcAdmissionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  admissionSchoolCollageNames: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const admissionSlice = createSlice({
  name: "admission",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.autoFeedback.message = "";
      state.concessionRemove.message = "";
      state.cancelAdmissionById.message = "";
      state.editBasicDataById.message = "";
      state.createAdmission.message = "";
      state.statusUpdate.message = "";
      state.installmentModify.message = "";
      state.deleteAdmissionOtherDocumentById.message = "";
      state.createRemark.message = "";
      state.courseModifyById.message = "";
      state.getById.message = "";
      state.assignBatch.message = "";
      state.unAssignBatch.message = "";
      state.batchTransfer.message = "";
      state.courseCompleted.message = "";
      state.updatePostalCommunication.message = "";
      state.updateParentsDetails.message = "";
      state.educationAndProfessionDetails.message = "";
      state.updateDocuments.message = "";
      state.grIdData.message = "";
      state.concessionApplyUpdate.message = "";
      state.searchAdmissionCsvEmailSendData.message = "";
      state.reverifiyCRMRecord.message = "";
      state.admissionCrmHistoryData.message = "";
      state.bulkRemark.message = "";
      state.crmCounsellorData.message = "";
      state.pushedCrmLeadEnquirie.message = "";
      state.createPenalty.message = "";
      state.penaltyData.message = "";
      state.getCRMLead.message = "";
      state.autoUpdateCRMLead.message = "";
      state.appStundetFeedBackData.message = "";
      state.admissionRevision.message = "";
      state.performanceModifyById.message = "";
      state.admissionSchoolCollageNames.message = "";
    },
    clearState: (state) => {
      state.getById.data = initialState.getById.data as any;
    },
    clearUnpaidInstallmentListState: (state) => {
      state.unpaidInstallmentListData.data =
        initialState.unpaidInstallmentListData.data;
    },
  },
  extraReducers: {
    [searchAdmissionData.pending.type]: (state) => {
      state.admissionData.loading = true;
    },
    [searchAdmissionData.fulfilled.type]: (state, action) => {
      state.admissionData.data = action.payload.data;
      state.admissionData.message = action.payload.message;
      state.admissionData.loading = false;
      state.admissionData.hasErrors = false;
    },
    [searchAdmissionData.rejected.type]: (state, action) => {
      state.admissionData.loading = false;
      state.admissionData.hasErrors = true;
      state.admissionData.message = action.error.message;
    },

    // Concession Data
    [searchConcessionData.pending.type]: (state) => {
      state.admissionData.loading = true;
    },
    [searchConcessionData.fulfilled.type]: (state, action) => {
      state.admissionData.data = action.payload.data;
      state.admissionData.message = action.payload.message;
      state.admissionData.loading = false;
      state.admissionData.hasErrors = false;
    },
    [searchConcessionData.rejected.type]: (state, action) => {
      state.admissionData.loading = false;
      state.admissionData.hasErrors = true;
      state.admissionData.message = action.error.message;
    },

    // Admission CSV file send email
    [searchAdmissionCsvEmailSendData.pending.type]: (state) => {
      state.searchAdmissionCsvEmailSendData.loading = true;
    },
    [searchAdmissionCsvEmailSendData.fulfilled.type]: (state, action) => {
      state.searchAdmissionCsvEmailSendData.data = action.payload.data;
      state.searchAdmissionCsvEmailSendData.message = action.payload.message;
      state.searchAdmissionCsvEmailSendData.loading = false;
      state.searchAdmissionCsvEmailSendData.hasErrors = false;
    },
    [searchAdmissionCsvEmailSendData.rejected.type]: (state, action) => {
      state.searchAdmissionCsvEmailSendData.loading = false;
      state.searchAdmissionCsvEmailSendData.hasErrors = true;
      state.searchAdmissionCsvEmailSendData.message = action.error.message;
    },

    [searchMissingAdmissionData.pending.type]: (state) => {
      state.admissionMissingData.loading = true;
    },
    [searchMissingAdmissionData.fulfilled.type]: (state, action) => {
      state.admissionMissingData.data = action.payload.data;
      state.admissionMissingData.message = action.payload.message;
      state.admissionMissingData.loading = false;
      state.admissionMissingData.hasErrors = false;
    },
    [searchMissingAdmissionData.rejected.type]: (state, action) => {
      state.admissionMissingData.loading = false;
      state.admissionMissingData.hasErrors = true;
      state.admissionMissingData.message = action.error.message;
    },

    [cancelAdmission.pending.type]: (state) => {
      state.cancelAdmissionById.loading = true;
    },
    [cancelAdmission.fulfilled.type]: (state, action) => {
      state.cancelAdmissionById.message = action.payload.message;
      state.cancelAdmissionById.loading = false;
      state.cancelAdmissionById.hasErrors = false;
    },
    [cancelAdmission.rejected.type]: (state, action) => {
      state.cancelAdmissionById.loading = false;
      state.cancelAdmissionById.hasErrors = true;
      state.cancelAdmissionById.message = action.error.message;
    },

    [editAdmissionBasicDataById.pending.type]: (state) => {
      state.editBasicDataById.loading = true;
    },
    [editAdmissionBasicDataById.fulfilled.type]: (state, action) => {
      state.editBasicDataById.message = action.payload.message;
      state.editBasicDataById.loading = false;
      state.editBasicDataById.hasErrors = false;
    },
    [editAdmissionBasicDataById.rejected.type]: (state, action) => {
      state.editBasicDataById.loading = false;
      state.editBasicDataById.hasErrors = true;
      state.editBasicDataById.message = action.error.message;
    },

    // batch transfer
    [admissionBranchTransfer.pending.type]: (state) => {
      state.batchTransfer.loading = true;
    },
    [admissionBranchTransfer.fulfilled.type]: (state, action) => {
      state.batchTransfer.message = action.payload.message;
      state.batchTransfer.loading = false;
      state.batchTransfer.hasErrors = false;
    },
    [admissionBranchTransfer.rejected.type]: (state, action) => {
      state.batchTransfer.loading = false;
      state.batchTransfer.hasErrors = true;
      state.batchTransfer.message = action.error.message;
    },

    //create Admission
    [createAdmission.pending.type]: (state) => {
      state.createAdmission.loading = true;
    },
    [createAdmission.fulfilled.type]: (state, action) => {
      state.createAdmission.data = action.payload?.data;
      state.createAdmission.message = action.payload.message;
      state.createAdmission.loading = false;
      state.createAdmission.hasErrors = false;
    },
    [createAdmission.rejected.type]: (state, action) => {
      state.createAdmission.loading = false;
      state.createAdmission.hasErrors = true;
      state.createAdmission.message = action.error.message;
    },

    // update Admission
    [updateAdmissionStatus.pending.type]: (state) => {
      state.statusUpdate.loading = true;
    },
    [updateAdmissionStatus.fulfilled.type]: (state, action) => {
      state.statusUpdate.message = action.payload.message;
      state.statusUpdate.loading = false;
      state.statusUpdate.hasErrors = false;
    },
    [updateAdmissionStatus.rejected.type]: (state, action) => {
      state.statusUpdate.loading = false;
      state.statusUpdate.hasErrors = true;
      state.statusUpdate.message = action.error.message;
    },

    //create Remarks
    [createNewRemark.pending.type]: (state) => {
      state.createRemark.loading = true;
    },
    [createNewRemark.fulfilled.type]: (state, action) => {
      state.createRemark.message = action.payload.message;
      state.createRemark.hasErrors = action.payload.error;
      state.createRemark.loading = false;
      state.createRemark.hasErrors = false;
    },
    [createNewRemark.rejected.type]: (state, action) => {
      state.createRemark.loading = false;
      state.createRemark.hasErrors = true;
      state.createRemark.message = action.error.message;
    },

    //create Bulk Remarks
    [createBulkRemark.pending.type]: (state) => {
      state.bulkRemark.loading = true;
    },
    [createBulkRemark.fulfilled.type]: (state, action) => {
      state.bulkRemark.message = action.payload.message;
      state.bulkRemark.hasErrors = action.payload.error;
      state.bulkRemark.loading = false;
      state.bulkRemark.hasErrors = false;
    },
    [createBulkRemark.rejected.type]: (state, action) => {
      state.bulkRemark.loading = false;
      state.bulkRemark.hasErrors = true;
      state.bulkRemark.message = action.error.message;
    },

    //Get Remarks
    [getAdmissionRemarksData.pending.type]: (state) => {
      state.remarksData.loading = true;
    },
    [getAdmissionRemarksData.fulfilled.type]: (state, action) => {
      state.remarksData.data = action.payload.data;
      state.remarksData.message = action.payload.message;
      state.remarksData.loading = false;
      state.remarksData.hasErrors = false;
    },
    [getAdmissionRemarksData.rejected.type]: (state, action) => {
      state.remarksData.loading = false;
      state.remarksData.hasErrors = true;
      state.remarksData.message = action.error.message;
    },

    //Get Gr Id Data
    [getGrIdData.pending.type]: (state) => {
      state.grIdData.loading = true;
    },
    [getGrIdData.fulfilled.type]: (state, action) => {
      state.grIdData.data = action.payload.data;
      state.grIdData.message = action.payload.message;
      state.grIdData.loading = false;
      state.grIdData.hasErrors = false;
    },
    [getGrIdData.rejected.type]: (state, action) => {
      state.grIdData.loading = false;
      state.grIdData.hasErrors = true;
      state.grIdData.message = action.error.message;
    },

    // couseInfo data
    [courseInfo.pending.type]: (state) => {
      state.courseInfo.loading = true;
    },
    [courseInfo.fulfilled.type]: (state, action) => {
      state.courseInfo.data = action.payload.data;
      state.courseInfo.message = action.payload.message;
      state.courseInfo.loading = false;
      state.courseInfo.hasErrors = false;
    },
    [courseInfo.rejected.type]: (state, action) => {
      state.courseInfo.loading = false;
      state.courseInfo.hasErrors = true;
      state.courseInfo.message = action.error.message;
    },

    // assign batch
    [assignBatch.pending.type]: (state) => {
      state.assignBatch.loading = true;
    },
    [assignBatch.fulfilled.type]: (state, action) => {
      state.assignBatch.message = action.payload.message;
      state.assignBatch.loading = false;
      state.assignBatch.hasErrors = false;
    },
    [assignBatch.rejected.type]: (state, action) => {
      state.assignBatch.loading = false;
      state.assignBatch.hasErrors = true;
      state.assignBatch.message = action.error.message;
    },

    // un-assign batch
    [unAssignBatch.pending.type]: (state) => {
      state.unAssignBatch.loading = true;
    },
    [unAssignBatch.fulfilled.type]: (state, action) => {
      state.unAssignBatch.message = action.payload.message;
      state.unAssignBatch.loading = false;
      state.unAssignBatch.hasErrors = false;
    },
    [unAssignBatch.rejected.type]: (state, action) => {
      state.unAssignBatch.loading = false;
      state.unAssignBatch.hasErrors = true;
      state.unAssignBatch.message = action.error.message;
    },

    // Course modification by id
    [courseModificationById.pending.type]: (state) => {
      state.courseModifyById.loading = true;
    },
    [courseModificationById.fulfilled.type]: (state, action) => {
      state.courseModifyById.message = action.payload.message;
      state.courseModifyById.loading = false;
      state.courseModifyById.hasErrors = false;
    },
    [courseModificationById.rejected.type]: (state, action) => {
      state.courseModifyById.loading = false;
      state.courseModifyById.hasErrors = true;
      state.courseModifyById.message = action.error.message;
    },

    // get by id
    [getAdmissionDetailsById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getAdmissionDetailsById.fulfilled.type]: (state, action) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getAdmissionDetailsById.rejected.type]: (state, action) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },

    // course as complete
    [courseCompleted.pending.type]: (state) => {
      state.courseCompleted.loading = true;
    },
    [courseCompleted.fulfilled.type]: (state, action) => {
      state.courseCompleted.message = action.payload.message;
      state.courseCompleted.loading = false;
      state.courseCompleted.hasErrors = false;
    },
    [courseCompleted.rejected.type]: (state, action) => {
      state.courseCompleted.loading = false;
      state.courseCompleted.hasErrors = true;
      state.courseCompleted.message = action.error.message;
    },

    // UPDATE Postal Communication
    [updatePostalCommunication.pending.type]: (state) => {
      state.updatePostalCommunication.loading = true;
    },
    [updatePostalCommunication.fulfilled.type]: (state, action) => {
      state.updatePostalCommunication.message = action.payload.message;
      state.updatePostalCommunication.loading = false;
      state.updatePostalCommunication.hasErrors = false;
    },
    [updatePostalCommunication.rejected.type]: (state, action) => {
      state.updatePostalCommunication.loading = false;
      state.updatePostalCommunication.hasErrors = true;
      state.updatePostalCommunication.message = action.error.message;
    },

    // UPDATE Parent Details
    [updateParentsDetails.pending.type]: (state) => {
      state.updateParentsDetails.loading = true;
    },
    [updateParentsDetails.fulfilled.type]: (state, action) => {
      state.updateParentsDetails.message = action.payload.message;
      state.updateParentsDetails.loading = false;
      state.updateParentsDetails.hasErrors = false;
    },
    [updateParentsDetails.rejected.type]: (state, action) => {
      state.updateParentsDetails.loading = false;
      state.updateParentsDetails.hasErrors = true;
      state.updateParentsDetails.message = action.error.message;
    },

    // UPDATE Education And Profession Details
    [updateEducationAndProfessionDetails.pending.type]: (state) => {
      state.educationAndProfessionDetails.loading = true;
    },
    [updateEducationAndProfessionDetails.fulfilled.type]: (state, action) => {
      state.educationAndProfessionDetails.message = action.payload.message;
      state.educationAndProfessionDetails.loading = false;
      state.educationAndProfessionDetails.hasErrors = false;
    },
    [updateEducationAndProfessionDetails.rejected.type]: (state, action) => {
      state.educationAndProfessionDetails.loading = false;
      state.educationAndProfessionDetails.hasErrors = true;
      state.educationAndProfessionDetails.message = action.error.message;
    },

    // UPDATE Documents
    [updateDocuments.pending.type]: (state) => {
      state.updateDocuments.loading = true;
    },
    [updateDocuments.fulfilled.type]: (state, action) => {
      state.updateDocuments.message = action.payload.message;
      state.updateDocuments.loading = false;
      state.updateDocuments.hasErrors = false;
    },
    [updateDocuments.rejected.type]: (state, action) => {
      state.updateDocuments.loading = false;
      state.updateDocuments.hasErrors = true;
      state.updateDocuments.message = action.error.message;
    },

    // DELETE Admission other Documents

    [deleteAdmissionOtherDocumentById.pending.type]: (state) => {
      state.deleteAdmissionOtherDocumentById.loading = true;
    },
    [deleteAdmissionOtherDocumentById.fulfilled.type]: (state, action) => {
      state.deleteAdmissionOtherDocumentById.loading = false;
      state.deleteAdmissionOtherDocumentById.hasErrors = false;
      state.deleteAdmissionOtherDocumentById.message = action.payload.message;
    },
    [deleteAdmissionOtherDocumentById.rejected.type]: (state, action) => {
      state.deleteAdmissionOtherDocumentById.loading = false;
      state.deleteAdmissionOtherDocumentById.hasErrors = true;
      state.deleteAdmissionOtherDocumentById.message = action.error.message;
    },

    // installmentModify
    [installmentModify.pending.type]: (state) => {
      state.installmentModify.loading = true;
    },
    [installmentModify.fulfilled.type]: (state, action) => {
      state.installmentModify.message = action.payload.message;
      state.installmentModify.loading = false;
      state.installmentModify.hasErrors = false;
    },
    [installmentModify.rejected.type]: (state, action) => {
      state.installmentModify.loading = false;
      state.installmentModify.hasErrors = true;
      state.installmentModify.message = action.error.message;
    },

    //unAssignBatch
    [searchUnAssignBatchAdmission.pending.type]: (state) => {
      state.unAssignBatchAdmissionData.loading = true;
    },
    [searchUnAssignBatchAdmission.fulfilled.type]: (state, action) => {
      state.unAssignBatchAdmissionData.data = action.payload.data;
      state.unAssignBatchAdmissionData.message = action.payload.message;
      state.unAssignBatchAdmissionData.loading = false;
      state.unAssignBatchAdmissionData.hasErrors = false;
    },
    [searchUnAssignBatchAdmission.rejected.type]: (state, action) => {
      state.unAssignBatchAdmissionData.loading = false;
      state.unAssignBatchAdmissionData.hasErrors = true;
      state.unAssignBatchAdmissionData.message = action.error.message;
    },

    //missingCRMData
    [missingCRMDetails.pending.type]: (state) => {
      state.missingCRMData.loading = true;
    },
    [missingCRMDetails.fulfilled.type]: (state, action) => {
      state.missingCRMData.data = action.payload.data;
      state.missingCRMData.message = action.payload.message;
      state.missingCRMData.loading = false;
      state.missingCRMData.hasErrors = false;
    },
    [missingCRMDetails.rejected.type]: (state, action) => {
      state.missingCRMData.loading = false;
      state.missingCRMData.hasErrors = true;
      state.missingCRMData.message = action.error.message;
    },

    //concession apply
    [concessionApplyUpdate.pending.type]: (state) => {
      state.concessionApplyUpdate.loading = true;
    },
    [concessionApplyUpdate.fulfilled.type]: (state, action) => {
      state.concessionApplyUpdate.message = action.payload.message;
      state.concessionApplyUpdate.loading = false;
      state.concessionApplyUpdate.hasErrors = false;
    },
    [concessionApplyUpdate.rejected.type]: (state, action) => {
      state.concessionApplyUpdate.loading = false;
      state.concessionApplyUpdate.hasErrors = true;
      state.concessionApplyUpdate.message = action.error.message;
    },

    // reverifiy CRM record
    [verifiyCRMRecord.pending.type]: (state) => {
      state.reverifiyCRMRecord.loading = true;
    },
    [verifiyCRMRecord.fulfilled.type]: (state, action) => {
      state.reverifiyCRMRecord.message = action.payload.message;
      state.reverifiyCRMRecord.loading = false;
      state.reverifiyCRMRecord.hasErrors = false;
    },
    [verifiyCRMRecord.rejected.type]: (state, action) => {
      state.reverifiyCRMRecord.loading = false;
      state.reverifiyCRMRecord.hasErrors = true;
      state.reverifiyCRMRecord.message = action.error.message;
    },

    //Get admission CRM Lead History
    [crmLeadHistoryRecord.pending.type]: (state) => {
      state.admissionCrmHistoryData.loading = true;
    },
    [crmLeadHistoryRecord.fulfilled.type]: (state, action) => {
      state.admissionCrmHistoryData.data = action.payload.data;
      state.admissionCrmHistoryData.message = action.payload.message;
      state.admissionCrmHistoryData.loading = false;
      state.admissionCrmHistoryData.hasErrors = false;
    },
    [crmLeadHistoryRecord.rejected.type]: (state, action) => {
      state.admissionCrmHistoryData.loading = false;
      state.admissionCrmHistoryData.hasErrors = true;
      state.admissionCrmHistoryData.message = action.error.message;
    },

    //Get CRM Lead Enquiries
    [getCRMLeadEnquiries.pending.type]: (state) => {
      state.crmLeadEnquiries.loading = true;
    },
    [getCRMLeadEnquiries.fulfilled.type]: (state, action) => {
      state.crmLeadEnquiries.data = action.payload.data;
      state.crmLeadEnquiries.message = action.payload.message;
      state.crmLeadEnquiries.loading = false;
      state.crmLeadEnquiries.hasErrors = false;
    },
    [getCRMLeadEnquiries.rejected.type]: (state, action) => {
      state.crmLeadEnquiries.loading = false;
      state.crmLeadEnquiries.hasErrors = true;
      state.crmLeadEnquiries.message = action.error.message;
    },

    // Push CRM Lead Enquiries
    [pushCRMLeadEnquirie.pending.type]: (state) => {
      state.pushedCrmLeadEnquirie.loading = true;
    },
    [pushCRMLeadEnquirie.fulfilled.type]: (state, action) => {
      state.pushedCrmLeadEnquirie.message = action.payload.message;
      state.pushedCrmLeadEnquirie.loading = false;
      state.pushedCrmLeadEnquirie.hasErrors = false;
    },
    [pushCRMLeadEnquirie.rejected.type]: (state, action) => {
      state.pushedCrmLeadEnquirie.loading = false;
      state.pushedCrmLeadEnquirie.hasErrors = true;
      state.pushedCrmLeadEnquirie.message = action.error.message;
    },

    //Get CRM Counsellor
    [getCRMCounsellor.pending.type]: (state) => {
      state.crmCounsellorData.loading = true;
    },
    [getCRMCounsellor.fulfilled.type]: (state, action) => {
      state.crmCounsellorData.result = action.payload;
      state.crmCounsellorData.message = action.payload.message;
      state.crmCounsellorData.loading = false;
      state.crmCounsellorData.hasErrors = false;
    },
    [getCRMCounsellor.rejected.type]: (state, action) => {
      state.crmCounsellorData.loading = false;
      state.crmCounsellorData.hasErrors = true;
      state.crmCounsellorData.message = action.error.message;
    },

    //concession remove
    [concessionRemove.pending.type]: (state) => {
      state.concessionRemove.loading = true;
    },
    [concessionRemove.fulfilled.type]: (state, action) => {
      state.concessionRemove.message = action.payload.message;
      state.concessionRemove.loading = false;
      state.concessionRemove.hasErrors = false;
    },
    [concessionRemove.rejected.type]: (state, action) => {
      state.concessionRemove.loading = false;
      state.concessionRemove.hasErrors = true;
      state.concessionApplyUpdate.message = action.error.message;
    },

    //create Penalty
    [createPenalty.pending.type]: (state) => {
      state.createPenalty.loading = true;
    },
    [createPenalty.fulfilled.type]: (state, action) => {
      state.createPenalty.message = action.payload.message;
      state.createPenalty.hasErrors = action.payload.error;
      state.createPenalty.loading = false;
      state.createPenalty.hasErrors = false;
    },
    [createPenalty.rejected.type]: (state, action) => {
      state.createPenalty.loading = false;
      state.createPenalty.hasErrors = true;
      state.createPenalty.message = action.error.message;
    },

    //Search Penalty Data
    [searchPenaltyData.pending.type]: (state) => {
      state.penaltyData.loading = true;
    },
    [searchPenaltyData.fulfilled.type]: (state, action) => {
      state.penaltyData.data = action.payload.data;
      state.penaltyData.message = action.payload.message;
      state.penaltyData.loading = false;
      state.penaltyData.hasErrors = false;
    },
    [searchPenaltyData.rejected.type]: (state, action) => {
      state.penaltyData.loading = false;
      state.penaltyData.hasErrors = true;
      state.penaltyData.message = action.error.message;
    },

    // auto -feedback
    [autoFeedback.pending.type]: (state) => {
      state.autoFeedback.loading = true;
    },
    [autoFeedback.fulfilled.type]: (state, action) => {
      state.autoFeedback.data = action.payload.data;
      state.autoFeedback.message = action.payload.message;
      state.autoFeedback.loading = false;
      state.autoFeedback.hasErrors = false;
    },
    [autoFeedback.rejected.type]: (state, action) => {
      state.autoFeedback.loading = false;
      state.autoFeedback.hasErrors = true;
      state.autoFeedback.message = action.error.message;
    },

    //Get CRM Lead
    [getCRMLead.pending.type]: (state) => {
      state.getCRMLead.loading = true;
    },
    [getCRMLead.fulfilled.type]: (state, action) => {
      state.getCRMLead.result = action.payload;
      state.getCRMLead.message = action.payload.message;
      state.getCRMLead.loading = false;
      state.getCRMLead.hasErrors = false;
    },
    [getCRMLead.rejected.type]: (state, action) => {
      state.getCRMLead.loading = false;
      state.getCRMLead.hasErrors = true;
      state.getCRMLead.message = action.error.message;
    },

    // Auto Update and create CRM Lead
    [autoUpdateCRMLead.pending.type]: (state) => {
      state.autoUpdateCRMLead.loading = true;
    },
    [autoUpdateCRMLead.fulfilled.type]: (state, action) => {
      state.autoUpdateCRMLead.message = action.payload.message;
      state.autoUpdateCRMLead.loading = false;
      state.autoUpdateCRMLead.hasErrors = false;
    },
    [autoUpdateCRMLead.rejected.type]: (state, action) => {
      state.autoUpdateCRMLead.loading = false;
      state.autoUpdateCRMLead.hasErrors = true;
      state.autoUpdateCRMLead.message = action.error.message;
    },

    // get app stundet feedback by admisison id
    [getAppStundetFeedBackById.pending.type]: (state) => {
      state.appStundetFeedBackData.loading = true;
    },
    [getAppStundetFeedBackById.fulfilled.type]: (state, action) => {
      state.appStundetFeedBackData.data = action.payload.data;
      state.appStundetFeedBackData.message = action.payload.message;
      state.appStundetFeedBackData.loading = false;
      state.appStundetFeedBackData.hasErrors = false;
    },
    [getAppStundetFeedBackById.rejected.type]: (state, action) => {
      state.appStundetFeedBackData.loading = false;
      state.appStundetFeedBackData.hasErrors = true;
      state.appStundetFeedBackData.message = action.error.message;
    },

    // Update sub status admission revision
    [admissionRevision.pending.type]: (state) => {
      state.admissionRevision.loading = true;
    },
    [admissionRevision.fulfilled.type]: (state, action) => {
      state.admissionRevision.message = action.payload.message;
      state.admissionRevision.loading = false;
      state.admissionRevision.hasErrors = false;
    },
    [admissionRevision.rejected.type]: (state, action) => {
      state.admissionRevision.loading = false;
      state.admissionRevision.hasErrors = true;
      state.admissionRevision.message = action.error.message;
    },

    // Performance modification by id
    [performanceModificationById.pending.type]: (state) => {
      state.performanceModifyById.loading = true;
    },
    [performanceModificationById.fulfilled.type]: (state, action) => {
      state.performanceModifyById.message = action.payload.message;
      state.performanceModifyById.loading = false;
      state.performanceModifyById.hasErrors = false;
    },
    [performanceModificationById.rejected.type]: (state, action) => {
      state.performanceModifyById.loading = false;
      state.performanceModifyById.hasErrors = true;
      state.performanceModifyById.message = action.error.message;
    },

    //Get Student Unpaid Installments by gr_id
    [getStudentUnpaidInstallment.pending.type]: (state) => {
      state.unpaidInstallmentListData.loading = true;
    },
    [getStudentUnpaidInstallment.fulfilled.type]: (state, action) => {
      state.unpaidInstallmentListData.data = action.payload.data;
      state.unpaidInstallmentListData.message = action.payload.message;
      state.unpaidInstallmentListData.loading = false;
      state.unpaidInstallmentListData.hasErrors = false;
    },
    [getStudentUnpaidInstallment.rejected.type]: (state, action) => {
      state.unpaidInstallmentListData.loading = false;
      state.unpaidInstallmentListData.hasErrors = true;
      state.unpaidInstallmentListData.message = action.error.message;
    },

    //Get Nsdc Admission Data
    [getNsdcAdmissionData.pending.type]: (state) => {
      state.getNsdcAdmissionData.loading = true;
    },
    [getNsdcAdmissionData.fulfilled.type]: (state, action) => {
      state.getNsdcAdmissionData.data = action.payload.data;
      state.getNsdcAdmissionData.message = action.payload.message;
      state.getNsdcAdmissionData.loading = false;
      state.getNsdcAdmissionData.hasErrors = false;
    },
    [getNsdcAdmissionData.rejected.type]: (state, action) => {
      state.getNsdcAdmissionData.loading = false;
      state.getNsdcAdmissionData.hasErrors = true;
      state.getNsdcAdmissionData.message = action.error.message;
    },

    // fetch admission school/collage names
    [searchSchoolCollageNames.pending.type]: (state) => {
      state.admissionSchoolCollageNames.loading = true;
    },
    [searchSchoolCollageNames.fulfilled.type]: (state, action) => {
      state.admissionSchoolCollageNames.data = action.payload.data;
      state.admissionSchoolCollageNames.message = action.payload.message;
      state.admissionSchoolCollageNames.loading = false;
      state.admissionSchoolCollageNames.hasErrors = false;
    },
    [searchSchoolCollageNames.rejected.type]: (state, action) => {
      state.admissionSchoolCollageNames.loading = false;
      state.admissionSchoolCollageNames.hasErrors = true;
      state.admissionSchoolCollageNames.message = action.error.message;
    },
  },
});

// A selector
export const admissionSelector = (state: RootState) => state.admission;

export const {
  clearRemoveMessage,
  clearUnpaidInstallmentListState,
  clearState,
} = admissionSlice.actions;

// The reducer
export default admissionSlice.reducer;
