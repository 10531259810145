import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { IUpdateHelpSupport } from "./HelpSupport.model";

class HelpSupportService {
  ENDPOINT = config.baseApiMasters + "/branches";
  
  public updateHelpSupportById = async (
    data: IUpdateHelpSupport
  ): Promise<IApiResponse<IUpdateHelpSupport>> => {
    const url = `${this.ENDPOINT}/${data.id}/update-help-center`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };
}

export default new HelpSupportService();
