import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IStudentAttendanceDetails,
  IStudentAttendanceDetailsByGrId,
  ISystemTrackerDetails,
} from "src/services/systemTracker/systemTracker.model";
import SystemTrackerService from "src/services/systemTracker/systemTracker.service";

export const getSystemTrackerDetails = createAsyncThunk(
  "get-pc-details/getSystemTrackerDetails",
  async (data: ISystemTrackerDetails) => {
    return SystemTrackerService.getSystemTrackerDetails(data);
  }
);

export const getStudentAttendanceDataBatchWise = createAsyncThunk(
  "get-batch-wise-student-attendance-data/getStudentAttendanceDataBatchWise",
  async (data: IStudentAttendanceDetails) => {
    return SystemTrackerService.getStudentAttendanceDataBatchWise(data);
  }
);

export const getAttendanceDataByDateAndGrIdWise = createAsyncThunk(
  "get-batch-wise-student-attendance-data/getAttendanceDataByDateAndGrIdWise",
  async (data: IStudentAttendanceDetailsByGrId) => {
    return SystemTrackerService.getAttendanceDataByDateAndGrIdWise(data);
  }
);
