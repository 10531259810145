import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import { config } from "src/utils";
import { IDashboardStudentListRecord } from "../studentList/studentList.model";
import {
  ICompanyVisitReport,
  IDegradeCancellationRemarksReport,
  IExpertSessionsReport,
  IStudentLabWiseChart,
  BranchUtlizaChart,
  IGetBranchWiseAllLabReport,
  IbranchWiseHoldStudentReport,
  ICounsellorDetailsRowData,
  IStudentExamMarks,
  IActiveStudentListReport,
  IAdmissionCounts,
  ITopicAnalysisReportData,
  monthWiseCollection,
  monthWiseWalkInDone,
} from "./report.model";
import { IStudentsTopicAnalysisData } from "src/components/SubCourseTopicWiseCDGradeShow/SubCourseTopicWiseCDGradeShow.model";

class ReportService {
  ENDPOINT_BASE_MASTER = config.baseApiMasters;
  ENDPOINT = config.baseApiStudent + "/students";
  NEW_END_POINT = config.baseApiStudent + "/es-planning";
  END_POINT_LAB_MASTERS_SLOTS = config.baseApiLabMasters + "/slots";
  ENDPOINT_REPORT = config.baseApiAdmission + "/reports";
  public getCompanyVisitReportData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<ICompanyVisitReport>> => {
    const url = `${this.ENDPOINT}/company-visit-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getDegradeAndCancelRemarkData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IDegradeCancellationRemarksReport>> => {
    const url = `${this.ENDPOINT}/degrade-cancellation-students-remarks-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getActiveStudentList = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IActiveStudentListReport[]>> => {
    const url = `${this.ENDPOINT}/get-active-student-list`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public sendBranchActiveStudentListCsv = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IActiveStudentListReport[]>> => {
    const url = `${this.ENDPOINT}/send-branch-active-student-list-csv`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public sendDegradeCancellationCsvFile = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IDegradeCancellationRemarksReport>> => {
    const url = `${this.ENDPOINT}/send-degrade-cancellation-students-remarks-report-history-csv-file`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getExpertSessionsReportData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IExpertSessionsReport>> => {
    const url = `${this.NEW_END_POINT}/expert-sessions-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getStudentsProjectAnalysisReportData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IStudentsTopicAnalysisData[]>> => {
    const url = `${this.ENDPOINT}/project-analysis-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public getLabWiseStudentData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IStudentLabWiseChart>> => {
    const url = `${this.END_POINT_LAB_MASTERS_SLOTS}/get-student-count-slot-wise`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getStudentsTopicAnalysisReportData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<ITopicAnalysisReportData[]>> => {
    const url = `${this.ENDPOINT}/topic-analysis-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getLabWiseBranchUtilizationData = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<BranchUtlizaChart>> => {
    const url = `${this.END_POINT_LAB_MASTERS_SLOTS}/get-branch-utilization-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getBranchWiseAllLabReport = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IGetBranchWiseAllLabReport>> => {
    const url = `${this.END_POINT_LAB_MASTERS_SLOTS}/get-branch-wise-all-lab-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public branchWiseHoldStudentReport = async (
    data: URLSearchParams | {}
  ): Promise<IApiResponse<IbranchWiseHoldStudentReport>> => {
    const url = `${this.END_POINT_LAB_MASTERS_SLOTS}/branch-wise-hold-student-slots`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public removeSlotById = async (id: number) => {
    const url = `${this.END_POINT_LAB_MASTERS_SLOTS}/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public searchCDGradeStudentHistory = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<IDashboardStudentListRecord>> => {
    const url = `${this.ENDPOINT}/get-cd-grde-history`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public downloadCsvCDGradeStudentHistory = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<IDashboardStudentListRecord>> => {
    const url = `${this.ENDPOINT}/send-cd-grade-history-csv-file`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchCounsellorData = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<ICounsellorDetailsRowData[]>> => {
    const url = `${this.ENDPOINT_BASE_MASTER}/counsellors-information`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchStudentExamMarks = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<IStudentExamMarks>> => {
    const url = `${this.ENDPOINT}/get-student-exam-marks`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public examMarkReportCsvEmail = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<IStudentExamMarks>> => {
    const url = `${this.ENDPOINT}/report-exam-marks-csv`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getMonthWiseAdmissionCounts = async (
    data: any
  ): Promise<IApiResponse<IAdmissionCounts[]>> => {
    const url = `${this.ENDPOINT_REPORT}/admissions-count`;
    return request({
      url,
      method: "GET",
      params: data.searchParams ? data.searchParams : data,
    }).then((res) => {
      return res.data;
    });
  };

  public monthwiseBranchCollection = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<monthWiseCollection>> => {
    const url = `${this.ENDPOINT_REPORT}/month-wise-collection-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public monthWiseWalkInDone = async (
    data: undefined | URLSearchParams | {}
  ): Promise<IApiResponse<monthWiseWalkInDone[]>> => {
    const url = `${this.ENDPOINT_REPORT}/month-wise-walk-in-done`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}
export default new ReportService();
