import { Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { ICollectionTargetRow } from "./CollectionTarget.model";

const CollectionTargetRow = (props: ICollectionTargetRow) => {
  const {
    index,
    name,
    branchOptions,
    months,
    targetAchievementData,
    ...restField
  } = props;

  const [totalSum, setTotalSum] = useState<number>(0);
  const inputValues = useRef<Record<string, number>>(
    months.reduce<Record<string, number>>((acc, month) => {
      acc[month] = targetAchievementData?.[month] || 0;
      return acc;
    }, {})
  );

  useEffect(() => {
    calculateTotal(inputValues.current);
  }, [targetAchievementData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const value = parseFloat(e.target.value) || 0;
    const newInputValues = { ...inputValues.current, [inputName]: value };

    inputValues.current = newInputValues;
    calculateTotal(newInputValues);
  };

  const calculateTotal = (values: Record<string, number>) => {
    const sum = Object.values(values).reduce((acc, value) => acc + value, 0);
    setTotalSum(sum);
  };

  const renderInput = (month: string) => (
    <Form.Item {...restField} name={[name, month]}>
      <Input
        size="large"
        style={{ minWidth: "100px" }}
        onChange={(e) => handleChange(e, month)}
        onBeforeInput={(
          e: React.FormEvent<HTMLInputElement> & { data: string }
        ) => {
          if (!/[0-9.]/.test(e.data)) {
            e.preventDefault();
          }
        }}
      />
    </Form.Item>
  );

  return (
    <tr key={index}>
      <td style={{ textAlign: "center" }}>{index + 1}</td>
      <td style={{ textAlign: "center" }}>{branchOptions}</td>
      {months.map((month, monthIndex) => (
        <td key={monthIndex} style={{ textAlign: "center" }}>
          {renderInput(month)}
        </td>
      ))}
      <td style={{ textAlign: "center" }}>
        <b>{totalSum}</b>
      </td>
    </tr>
  );
};

export default CollectionTargetRow;
