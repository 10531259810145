import { Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { ITargetRawProps } from "./AdmissionTarget.model";

const TargetRaw = (props: ITargetRawProps) => {
  const {
    index,
    name,
    branchOptions,
    months,
    subcategories,
    targetAchievementData,
    ...restField
  } = props;

  const [rowTotals, setRowTotals] = useState<Record<string, number>>(
    subcategories.reduce((acc, subcat) => ({ ...acc, [subcat]: 0 }), {})
  );
  const inputValues = useRef<Record<string, number>>(
    months.reduce<Record<string, number>>((acc, month) => {
      subcategories.forEach((subcat) => {
        acc[`${month}_${subcat}`] =
          targetAchievementData?.[`${month}_${subcat}`] || 0;
      });
      return acc;
    }, {})
  );
  useEffect(() => {
    calculateRowTotals(inputValues.current);
  }, [targetAchievementData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const value = parseFloat(e.target.value) || 0;
    const newInputValues = { ...inputValues.current, [inputName]: value };

    inputValues.current = newInputValues;
    calculateRowTotals(newInputValues);
  };

  const calculateRowTotals = (values: Record<string, number>) => {
    const newTotals = subcategories.reduce((acc, subcat) => {
      acc[subcat] = Object.entries(values)
        .filter(([key]) => key.endsWith(`_${subcat}`))
        .reduce((sum, [, value]) => sum + value, 0);
      return acc;
    }, {} as Record<string, number>);

    setRowTotals(newTotals);
  };

  const renderInput = (month: string, subcat: string) => (
    <Form.Item {...restField} name={[name, `${month}_${subcat}`]}>
      <Input
        size="large"
        style={{ minWidth: "100px" }}
        onChange={(e) => handleChange(e, `${month}_${subcat}`)}
        onBeforeInput={(
          e: React.FormEvent<HTMLInputElement> & { data: string }
        ) => {
          if (!/[0-9.]/.test(e.data)) {
            e.preventDefault();
          }
        }}
      />
    </Form.Item>
  );

  return (
    <tr key={index}>
      <td style={{ textAlign: "center" }}>{index + 1}</td>
      <td style={{ textAlign: "center" }}>{branchOptions}</td>
      {months.map((month, monthIndex) => (
        <td key={monthIndex} style={{ textAlign: "center" }}>
          <table>
            <tbody>
              <tr>
                {subcategories.map((subcat, subIndex) => (
                  <td key={subIndex}>{renderInput(month, subcat)}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </td>
      ))}
      {subcategories.map((subcat) => (
        <td key={subcat} style={{ textAlign: "center" }}>
          <b>{rowTotals[subcat]}</b>
        </td>
      ))}
    </tr>
  );
};

export default TargetRaw;
