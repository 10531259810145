import { useEffect, useMemo, useState } from "react";
import { Col, Row, Card, DatePicker, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import ContainerHeader from "src/components/ContainerHeader";
import moment, { Moment } from "moment";
import { monthWiseWalkInDone } from "src/state/report/report.action";
import { IMonthWiseWalkInDone } from "src/services/report/report.model";

const MonthWiseWalkInDone = () => {
  const dispatch = useDispatch<AppDispatch>();
  const branchState = useAppSelector(branchSelector);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [monthlyWalkInDone, setMonthlyWalkInDone] = useState<any>([]);

  useEffect(() => {
    const monthStr = (selectedMonth + 1).toString().padStart(2, "0");
    const yearStr = selectedYear.toString();
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isAllBranch: true,
      })
    );
    dispatch(
      monthWiseWalkInDone({
        year: yearStr,
        month: monthStr,
        noLimit: true,
        orderBy: "name",
        order: "ASC",
      })
    );
  }, []);

  const dates = useMemo(() => {
    const daysInMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .daysInMonth();
    const datesArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      datesArray.push(i.toString().padStart(2, "0"));
    }
    return datesArray;
  }, [selectedYear, selectedMonth]);

  const fetchData = async () => {
    setLoading(true);

    const monthStr = (selectedMonth + 1).toString().padStart(2, "0");
    const yearStr = selectedYear.toString();

    try {
      const result = await dispatch(
        monthWiseWalkInDone({
          year: yearStr,
          month: monthStr,
          noLimit: true,
          orderBy: "name",
          order: "ASC",
        })
      ).unwrap();

      const data = result?.data;
      setMonthlyWalkInDone(data || []);
    } catch (error) {
      console.log("Error fetching monthwise walk in done data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, selectedMonth]);

  const branchOptions = branchState?.branchesData?.data?.rows || [];

  const getmonthlyWalkInDone = (branchId: number, day: string) => {
    const branchData = monthlyWalkInDone.find(
      (item: IMonthWiseWalkInDone) => item.branch_id === branchId
    );

    if (branchData && branchData.days) {
      const value = branchData.days[`day ${parseInt(day)}`] || "";
      return value ? value : "";
    }

    return "";
  };

  const getDayTotal = (dayNumber: number) => {
    const dayKey = `day ${parseInt(dayNumber.toString())}`;
    let total = 0;

    monthlyWalkInDone.forEach((branch: IMonthWiseWalkInDone) => {
      if (branch.days && dayKey in branch.days) {
        total += Number(branch.days[dayKey]) || 0;
      }
    });

    return total > 0 ? total : "";
  };

  const grandTotal = monthlyWalkInDone.reduce(
    (total: number, branch: IMonthWiseWalkInDone) => {
      return total + (branch.total || 0);
    },
    0
  );

  const handleMonthChange = (date: Moment | null) => {
    if (date) {
      setSelectedYear(date.year());
      setSelectedMonth(date.month());
    }
  };

  return (
    <>
      <Row
        align="middle"
        gutter={24}
        justify="space-between"
        className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"
      >
        <Col className="gx-mb-md-1">
          <ContainerHeader title={"Month Wise Walk-In-Done"} />
        </Col>

        <Col>
          <DatePicker
            getPopupContainer={(trigger) => trigger}
            value={moment().year(selectedYear).month(selectedMonth)}
            onChange={handleMonthChange}
            size="large"
            format="MM/YYYY"
            picker="month"
            style={{ width: "100%" }}
            className="custom-date-picker"
          />
        </Col>
      </Row>

      <Card className="rnw-card table-card faculty-signing-table">
        {loading ? (
          <Skeleton loading={loading} active />
        ) : (
          <div className="table-responsive">
            <table className="faculty-signing table-striped">
              <thead>
                <tr>
                  <th className="sr-no-col left-sticky">Sr No</th>
                  <th className="branch-col left-sticky">Branch Code</th>
                  {dates.map((date, index) => (
                    <th
                      key={index}
                      className="date-col"
                      style={{ width: "auto" }}
                    >
                      {date}
                    </th>
                  ))}
                  <th className="total-col right-sticky">Total</th>
                </tr>
              </thead>
              <tbody>
                {branchOptions.map((branch, index) => {
                  const branchData = monthlyWalkInDone.find(
                    (item: IMonthWiseWalkInDone) => item.branch_id === branch.id
                  );
                  return (
                    <tr key={branch.id}>
                      <td className="sr-no-col left-sticky">{index + 1}</td>
                      <td
                        className="branch-col left-sticky"
                        style={{ textAlign: "center" }}
                      >
                        {branch.code || "N/A"}
                      </td>
                      {dates.map((date, dateIndex) => (
                        <td
                          key={dateIndex}
                          className="date-col"
                          style={{ width: "auto" }}
                        >
                          {getmonthlyWalkInDone(branch.id, date)}
                        </td>
                      ))}
                      <td className="total-col right-sticky">
                        {branchData ? branchData.total : ""}
                      </td>
                    </tr>
                  );
                })}
                <tr className="table-footer">
                  <td className="sr-no-col left-sticky daily-total" colSpan={2}>
                    Total
                  </td>
                  {dates.map((date, index) => (
                    <td key={index} className="date-col font-weight-bold">
                      {getDayTotal(+date)}
                    </td>
                  ))}
                  <td className="total-col right-sticky font-weight-bold">
                    {grandTotal ? grandTotal : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Card>
    </>
  );
};

export default MonthWiseWalkInDone;
