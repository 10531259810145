import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { Checkbox, List, Typography } from "antd";
import "antd/dist/antd.css";
import { ListValue } from "./DragAndDropList.model";
import { TemplateShiningSheetTopic } from "src/pages/SubCourseTemplate/SubCourseTemplate.model";
import { ISubCourseTopicDetails } from "src/services/subCourseTopic/subCourseTopic.model";
import { SubTopic } from "src/services/templateSigningSheet/templateSigningSheet.model";

const DraggableList = (props: ListValue) => {
  const { value, onchange, changeValue } = props;
  const [data, setData] = useState<ISubCourseTopicDetails[]>([]);

  useEffect(() => {
    setData([...value]);
  }, [value]);

  const reorder = (
    list: SubTopic[] | ISubCourseTopicDetails[] | undefined,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list as TemplateShiningSheetTopic[]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (
    fromIndex: number,
    toIndex: number,
    type: string,
    index: number | string
  ) => {
    if (toIndex < 0) return;
    if (type === "LIST-ITEM") {
      const categories = reorder(data, fromIndex, toIndex);
      changeValue(categories);
      return setData(categories as ISubCourseTopicDetails[]);
    } else if (type === "SUBLIST-ITEM") {
      if (data[index as number]) {
        const subTopics = reorder(
          data[index as number].subTopics,
          fromIndex,
          toIndex
        );
        const categories = JSON.parse(JSON.stringify(data));
        categories[index as number].subTopics = subTopics;
        changeValue(categories);
        return setData(categories);
      }
    } else return new Error("NOT A VALID LIST");
  };

  return (
    <>
      <ReactDragListView
        nodeSelector=".ant-list.draggable"
        lineClassName="dragLine"
        onDragEnd={(fromIndex, toIndex) =>
          onDragEnd(fromIndex, toIndex, "LIST-ITEM", "")
        }
      >
        {data.map((topic: ISubCourseTopicDetails, index: number) => (
          <React.Fragment>
            <List
              header={
                <Typography.Text strong className="grabbable">
                  <Checkbox
                    style={{ fontWeight: 500 }}
                    onChange={onchange}
                    checked={topic.selected === true ? true : false}
                    value={{
                      topic: topic.id,
                      subTopic: topic?.subTopics?.map((item) => item.id),
                      sub_topic: null,
                      id: topic.id,
                    }}
                  >
                    {index + 1}.&nbsp;
                    {`${topic?.name
                      ?.replace(/&lt;/g, "<")
                      .replace(/&gt;/g, ">")}`}
                  </Checkbox>
                  <div className="grabbable">
                    <div className="sheet">
                      <div className="space">
                        {`${topic?.type ?? "N/A"}`}
                        {` [Duration: ${topic?.duration ?? "N/A"}${
                          topic?.marks ? ` | Marks: ${topic.marks}` : ""
                        }]`}
                      </div>
                    </div>
                  </div>
                </Typography.Text>
              }
              key={topic.id}
              className="draggable"
            >
              <ReactDragListView
                nodeSelector=".ant-list-item.draggable-item"
                lineClassName="dragLine"
                onDragEnd={(fromIndex, toIndex) =>
                  onDragEnd(fromIndex, toIndex, "SUBLIST-ITEM", index)
                }
              >
                {topic.subTopics?.map((subTopic: SubTopic, i: number) => (
                  <List.Item
                    key={JSON.stringify(subTopic)}
                    className="draggable-item gx-py-2  "
                  >
                    <Checkbox
                      style={{ fontWeight: 400, paddingLeft: "20px" }}
                      checked={subTopic?.selected === true ? true : false}
                      value={{
                        topic: topic.id,
                        sub_topic: i,
                        subTopic: subTopic.id,
                        id: topic.id,
                      }}
                      onChange={onchange}
                    >
                      <List.Item.Meta
                        title={
                          <>
                            {index + 1 + "." + (i + 1) + " "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: subTopic?.description
                                  ?.replace(/&lt;/g, "<")
                                  .replace(/&gt;/g, ">"),
                              }}
                            />
                          </>
                        }
                        className="grabbable"
                      />
                    </Checkbox>
                  </List.Item>
                ))}
              </ReactDragListView>
            </List>
          </React.Fragment>
        ))}
      </ReactDragListView>
    </>
  );
};

export default DraggableList;
