import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IHelpSupportState } from "./helpSupport.model";
import { updateHelpSupportById } from "./helpSupport.action";

export const initialState: IHelpSupportState = {

  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const helpSupportSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.editById.message = "";
    },
  },
  extraReducers: {
    [updateHelpSupportById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [updateHelpSupportById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [updateHelpSupportById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },
  },
});

// A selector
export const helpSupportSelector = (state: RootState) => state.helpSupport;

export const { clearRemoveMessage } = helpSupportSlice.actions;

// The reducer
export default helpSupportSlice.reducer;
