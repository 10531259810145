import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "src/utils/models/common";
import { IAdmissionBranchWiseReport, IAdmissionReport, IPackageReport, ISubCourseRevenue } from "./statistic.model";

class StatisticService {
    ENDPOINT = config.baseApiAdmission + "/statistics";

    public totalCompletionReport = async (
        data: IAdmissionReport | URLSearchParams | {}
    ): Promise<IApiResponse<IAdmissionReport[]>> => {
        const url = `${this.ENDPOINT}/status-wise-admission-report`;
        return request({ url, method: "GET", params: data }).then((res) => {
            return res.data;
        });
    };

    public packageRevenueReport = async (
        data: IPackageReport | URLSearchParams | {}
    ): Promise<IApiResponse<IPackageReport[]>> => {
        const url = `${this.ENDPOINT}/package-revenue-report`;
        return request({ url, method: "GET", params: data }).then((res) => {
            return res.data;
        });
    };

    public admissionReport = async (
        data: IAdmissionBranchWiseReport | URLSearchParams | {}
    ): Promise<IApiResponse<IAdmissionBranchWiseReport[]>> => {
        const url = `${this.ENDPOINT}/admission-vs-branch-wise-report`;
        return request({ url, method: "GET", params: data }).then((res) => {
            return res.data;
        });
    };
    
    public subCourseRevenueReport = async (
        data:ISubCourseRevenue | URLSearchParams | {}
    ): Promise<IApiResponse<ISubCourseRevenue[]>> => {
        const url = `${this.ENDPOINT}/subcourse-revenue-report`;
        return request({ url, method: "GET", params: data }).then((res) => {
            return res.data;
        });
    };
}

export default new StatisticService();