import { Button, Drawer, Space } from "antd";
import React from "react";
import { IDrawerProps } from "./Drawer.model";

const DrawerComponent = (props: IDrawerProps) => {
  const {
    title,
    onClose,
    visible,
    placement,
    size,
    width,
    className,
    footer,
    label,
    permission,
    children,
    destroyOnClose,
  } = props;

  const submitRef = React.createRef<HTMLElement>();

  const getResult = () => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children as JSX.Element, { myRef: submitRef });
    } else {
      return children({ myRef: submitRef });
    }
  };

  return (
    <Drawer
      className={className}
      title={title}
      placement={placement}
      onClose={onClose}
      visible={visible}
      size={size}
      width={width}
      destroyOnClose={destroyOnClose || false}
      footer={
        footer ? (
          <Space>
            {permission || permission === undefined ? (
              <Button
                className="btn-submit"
                onClick={() => {
                  if (submitRef && submitRef.current) {
                    submitRef.current.click();
                  }
                }}
                type="primary"
              >
                {label}
              </Button>
            ) : (
              <Button className="btn-submit" type="primary" disabled={true}>
                {label}
              </Button>
            )}
            <Button className="btn-cancel" onClick={onClose}>
              Cancel
            </Button>
          </Space>
        ) : null
      }
    >
      <div className="drawer-body-scroll drawer-input-picker">
        {getResult()}
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
