import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  createNewSubCourseQuestionBank,
  removeSubCourseQuestionBankById,
  searchSubCourseAndBatchWiseQuestionBankData,
  searchSubCourseQuestionBankData,
  updateSubCourseQuestionBankData,
} from "./subCourseQuestionBank.action";
import { IsubCourseQuestionBankState } from "./subCourseQuestionBank.model";

export const initialState: IsubCourseQuestionBankState = {
  searchSubCourseQuestionBankData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  searchSubCourseAndBatchWiseQuestionBankData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  createQuestionBank: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  updateById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const questionBankSlice = createSlice({
  name: "questionbank",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.createQuestionBank.message = "";
      state.removeById.message = "";
      state.updateById.message = "";
    },
  },
  extraReducers: {
    //get subcourseQuestionBankData
    [searchSubCourseQuestionBankData.pending.type]: (state) => {
      state.searchSubCourseQuestionBankData.loading = true;
    },
    [searchSubCourseQuestionBankData.fulfilled.type]: (state, action) => {
      state.searchSubCourseQuestionBankData.data = action.payload.data;
      state.searchSubCourseQuestionBankData.message = action.payload.message;
      state.searchSubCourseQuestionBankData.loading = false;
      state.searchSubCourseQuestionBankData.hasErrors = false;
    },
    [searchSubCourseQuestionBankData.rejected.type]: (state, action) => {
      state.searchSubCourseQuestionBankData.loading = false;
      state.searchSubCourseQuestionBankData.hasErrors = true;
      state.searchSubCourseQuestionBankData.message = action.error.message;
    },

    [searchSubCourseAndBatchWiseQuestionBankData.pending.type]: (state) => {
      state.searchSubCourseAndBatchWiseQuestionBankData.loading = true;
    },
    [searchSubCourseAndBatchWiseQuestionBankData.fulfilled.type]: (
      state,
      action
    ) => {
      state.searchSubCourseAndBatchWiseQuestionBankData.data =
        action.payload.data;
      state.searchSubCourseAndBatchWiseQuestionBankData.message =
        action.payload.message;
      state.searchSubCourseAndBatchWiseQuestionBankData.loading = false;
      state.searchSubCourseAndBatchWiseQuestionBankData.hasErrors = false;
    },
    [searchSubCourseAndBatchWiseQuestionBankData.rejected.type]: (
      state,
      action
    ) => {
      state.searchSubCourseAndBatchWiseQuestionBankData.loading = false;
      state.searchSubCourseAndBatchWiseQuestionBankData.hasErrors = true;
      state.searchSubCourseAndBatchWiseQuestionBankData.message =
        action.error.message;
    },

    // create questionBank
    [createNewSubCourseQuestionBank.pending.type]: (state) => {
      state.createQuestionBank.loading = true;
    },
    [createNewSubCourseQuestionBank.fulfilled.type]: (state, action) => {
      state.createQuestionBank.message = action.payload.message;
      state.createQuestionBank.hasErrors = action.payload.error;
      state.createQuestionBank.loading = false;
      state.createQuestionBank.hasErrors = false;
    },
    [createNewSubCourseQuestionBank.rejected.type]: (state, action) => {
      state.createQuestionBank.loading = false;
      state.createQuestionBank.hasErrors = true;
      state.createQuestionBank.message = action.error.message;
    },

    //Delete questionBank
    [removeSubCourseQuestionBankById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeSubCourseQuestionBankById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeSubCourseQuestionBankById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    //update questionBank

    [updateSubCourseQuestionBankData.pending.type]: (state) => {
      state.updateById.loading = true;
    },
    [updateSubCourseQuestionBankData.fulfilled.type]: (state, action) => {
      state.updateById.message = action.payload.message;
      state.updateById.loading = false;
      state.updateById.hasErrors = false;
    },
    [updateSubCourseQuestionBankData.rejected.type]: (state, action) => {
      state.updateById.loading = false;
      state.updateById.hasErrors = true;
      state.updateById.message = action.error.message;
    },
  },
});

// A selector
export const questionBankSelector = (state: RootState) => state.questionbank;

export const { clearRemoveMessage } = questionBankSlice.actions;

// The reducer
export default questionBankSlice.reducer;
