import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  getAttendanceDataByDateAndGrIdWise,
  getStudentAttendanceDataBatchWise,
  getSystemTrackerDetails,
} from "./systemTracker.action";
import { ISystemTrackerState } from "./systemTracker.model";

export const initialState: ISystemTrackerState = {
  getSystemTrackerDetails: {
    loading: false,
    hasErrors: false,
    message: "",
    result: {
      data: [],
    },
  },
  getStudentAttendanceDataBatchWise: {
    loading: false,
    hasErrors: false,
    message: "",
    result: {
      data: [],
    },
  },
  getAttendanceDataByDateAndGrIdWise: {
    loading: false,
    hasErrors: false,
    message: "",
    result: {
      data: [],
    },
  },
};

export const systemTrackerSlice = createSlice({
  name: "get-pc-details",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.getSystemTrackerDetails.message = "";
      state.getStudentAttendanceDataBatchWise.message = "";
    },
    clearSystemTrackerData: (state) => {
      state.getSystemTrackerDetails.result =
        initialState.getSystemTrackerDetails.result;
    },
  },
  extraReducers: {
    // get system tracker details
    [getSystemTrackerDetails.pending.type]: (state) => {
      state.getSystemTrackerDetails.loading = true;
    },
    [getSystemTrackerDetails.fulfilled.type]: (state, action) => {
      state.getSystemTrackerDetails.result = action.payload;
      state.getSystemTrackerDetails.message = action.payload.message;
      state.getSystemTrackerDetails.loading = false;
      state.getSystemTrackerDetails.hasErrors = false;
    },
    [getSystemTrackerDetails.rejected.type]: (state, action) => {
      state.getSystemTrackerDetails.loading = false;
      state.getSystemTrackerDetails.hasErrors = true;
      state.getSystemTrackerDetails.message = action.error.message;
    },

    // get student attendance data batch wise
    [getStudentAttendanceDataBatchWise.pending.type]: (state) => {
      state.getStudentAttendanceDataBatchWise.loading = true;
    },
    [getStudentAttendanceDataBatchWise.fulfilled.type]: (state, action) => {
      state.getStudentAttendanceDataBatchWise.result = action.payload;
      state.getStudentAttendanceDataBatchWise.message = action.payload.message;
      state.getStudentAttendanceDataBatchWise.loading = false;
      state.getStudentAttendanceDataBatchWise.hasErrors = false;
    },
    [getStudentAttendanceDataBatchWise.rejected.type]: (state, action) => {
      state.getStudentAttendanceDataBatchWise.loading = false;
      state.getStudentAttendanceDataBatchWise.hasErrors = true;
      state.getStudentAttendanceDataBatchWise.message = action.error.message;
    },

    // get student attendance data gr id and date wise
    [getAttendanceDataByDateAndGrIdWise.pending.type]: (state) => {
      state.getAttendanceDataByDateAndGrIdWise.loading = true;
    },
    [getAttendanceDataByDateAndGrIdWise.fulfilled.type]: (state, action) => {
      state.getAttendanceDataByDateAndGrIdWise.result = action.payload;
      state.getAttendanceDataByDateAndGrIdWise.message = action.payload.message;
      state.getAttendanceDataByDateAndGrIdWise.loading = false;
      state.getAttendanceDataByDateAndGrIdWise.hasErrors = false;
    },
    [getAttendanceDataByDateAndGrIdWise.rejected.type]: (state, action) => {
      state.getAttendanceDataByDateAndGrIdWise.loading = false;
      state.getAttendanceDataByDateAndGrIdWise.hasErrors = true;
      state.getAttendanceDataByDateAndGrIdWise.message = action.error.message;
    },
  },
});

// A selector
export const systemTrackerSelector = (state: RootState) => state.systemTracker;

export const { clearRemoveMessage, clearSystemTrackerData } =
  systemTrackerSlice.actions;

// The reducer
export default systemTrackerSlice.reducer;
