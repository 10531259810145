import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { ITargetAchievementState } from "./targetAchievement.model";
import {
  createNewAdmissionTargetAchievement,
  editAdmissionTargetAchievementById,
  getAdmissionTargetAchievementData,
  getAllTargetAchievementReportData,
} from "./targetAchievement.action";

export const initialState: ITargetAchievementState = {
  createNewAdmissionTargetAchievement: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  getAdmissionTargetAchievementData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  editAdmissionTargetAchievementById: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  getAllTargetAchievementReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const targetAchievementSlice = createSlice({
  name: "target-achievement",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.createNewAdmissionTargetAchievement.message = "";
      state.editAdmissionTargetAchievementById.message = "";
      state.getAdmissionTargetAchievementData.message = "";
      state.getAllTargetAchievementReportData.message = "";
    },
  },

  extraReducers: {
    [createNewAdmissionTargetAchievement.pending.type]: (state) => {
      state.createNewAdmissionTargetAchievement.loading = true;
    },
    [createNewAdmissionTargetAchievement.fulfilled.type]: (state, action) => {
      state.createNewAdmissionTargetAchievement.message =
        action.payload.message;
      state.createNewAdmissionTargetAchievement.hasErrors =
        action.payload.error;
      state.createNewAdmissionTargetAchievement.loading = false;
      state.createNewAdmissionTargetAchievement.hasErrors = false;
    },
    [createNewAdmissionTargetAchievement.rejected.type]: (state, action) => {
      state.createNewAdmissionTargetAchievement.loading = false;
      state.createNewAdmissionTargetAchievement.hasErrors = true;
      state.createNewAdmissionTargetAchievement.message = action.error.message;
    },

    [getAdmissionTargetAchievementData.pending.type]: (state) => {
      state.getAdmissionTargetAchievementData.loading = true;
    },
    [getAdmissionTargetAchievementData.fulfilled.type]: (state, action) => {
      state.getAdmissionTargetAchievementData.data = action.payload.data;
      state.getAdmissionTargetAchievementData.message = action.payload.message;
      state.getAdmissionTargetAchievementData.loading = false;
      state.getAdmissionTargetAchievementData.hasErrors = false;
    },
    [getAdmissionTargetAchievementData.rejected.type]: (state, action) => {
      state.getAdmissionTargetAchievementData.loading = false;
      state.getAdmissionTargetAchievementData.hasErrors = true;
      state.getAdmissionTargetAchievementData.message = action.error.message;
    },

    [editAdmissionTargetAchievementById.pending.type]: (state) => {
      state.editAdmissionTargetAchievementById.loading = true;
    },
    [editAdmissionTargetAchievementById.fulfilled.type]: (state, action) => {
      state.editAdmissionTargetAchievementById.message = action.payload.message;
      state.editAdmissionTargetAchievementById.loading = false;
      state.editAdmissionTargetAchievementById.hasErrors = false;
    },
    [editAdmissionTargetAchievementById.rejected.type]: (state, action) => {
      state.editAdmissionTargetAchievementById.loading = false;
      state.editAdmissionTargetAchievementById.hasErrors = true;
      state.editAdmissionTargetAchievementById.message = action.error.message;
    },

    [getAllTargetAchievementReportData.pending.type]: (state) => {
      state.getAllTargetAchievementReportData.loading = true;
    },
    [getAllTargetAchievementReportData.fulfilled.type]: (state, action) => {
      state.getAllTargetAchievementReportData.data = action.payload.data;
      state.getAllTargetAchievementReportData.message = action.payload.message;
      state.getAllTargetAchievementReportData.loading = false;
      state.getAllTargetAchievementReportData.hasErrors = false;
    },
    [getAllTargetAchievementReportData.rejected.type]: (state, action) => {
      state.getAllTargetAchievementReportData.loading = false;
      state.getAllTargetAchievementReportData.hasErrors = true;
      state.getAllTargetAchievementReportData.message = action.error.message;
    },
  },
});

export const targetAdmissionSelector = (state: RootState) =>
  state.targetAdmission;

export const { clearRemoveMessage } = targetAchievementSlice.actions;

export default targetAchievementSlice.reducer;
