import {
  Col,
  Row,
  Select,
  Form,
  Card,
  Input,
  Button,
  Typography,
  message,
  Skeleton,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchPackageData } from "src/state/package/package.action";
import { packageSelector } from "src/state/package/package.reducer";
import { CloseOutlined } from "@ant-design/icons";
import { showTooltip, trimObject } from "src/utils/helperFunction";
import { createExamResult } from "src/state/ExamResult/examResult.action";
import {
  examResultSelector,
  clearRemoveMessage,
} from "src/state/ExamResult/examResult.reducer";
import { IPackageRecord } from "../Package/Packages.model";
import {
  IFormValues,
  IPackageGrouped,
  IPoint,
  ISubcourseName,
  Payload,
} from "./index.model";
import FloatLabel from "src/components/Form/FloatLabel";
import { userSelector } from "src/state/users/user.reducer";
import { ZoneType } from "src/utils/constants/constant";
import AddRemoveButton from "src/components/AddRemoveButtons/AddRemoveButtons";

const { Option } = Select;

const ExamResult = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const branchState = useAppSelector(branchSelector);
  const packageState = useAppSelector(packageSelector);
  const examResultState = useAppSelector(examResultSelector);
  const [selectedBranch, setSelectedBranch] = useState<number | undefined>(
    undefined
  );
  const [selectedPackage, setSelectedPackage] = useState<number | undefined>(
    undefined
  );
  const [selectedPackageData, setSelectedPackageData] = useState<
    IPackageRecord | undefined
  >(undefined);
  const [subcourseNames, setSubcourseNames] = useState<ISubcourseName[]>([]);
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const [form] = Form.useForm();
  const [btn, setBtn] = useState(false);
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  const rules = {
    package: [{ required: true, message: "Please Enter Package!" }],
  };

  useEffect(() => {
    dispatch(
      searchPackageData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (examResultState.createExamResult.message) {
      if (examResultState.createExamResult.hasErrors) {
        message.error(examResultState.createExamResult.message);
      } else {
        message.success(examResultState.createExamResult.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [examResultState.createExamResult.message]);

  const branch_id = useMemo(
    () => branchState.branchesData.data.rows[0]?.id,
    [branchState.branchesData.data.rows]
  );

  const filteredPackages = useMemo(() => {
    const rows = packageState.packageData.data?.rows || [];

    if (currentUserZone?.type !== ZoneType.PRIVATE) {
      return rows.filter((data) =>
        data.package_branches.some(
          (branch) => branch.branch_id === selectedBranch
        )
      );
    } else {
      return rows.filter((data) =>
        data.package_branches.some((branch) => branch.branch_id === branch_id)
      );
    }
  }, [
    selectedBranch,
    currentUserZone?.type,
    packageState.packageData.data?.rows,
  ]);

  useEffect(() => {
    if (selectedPackage !== undefined) {
      const selectedData = filteredPackages.find(
        (data) => data.id === selectedPackage
      );
      setSelectedPackageData(selectedData as IPackageRecord | undefined);
    }
  }, [selectedPackage, filteredPackages]);

  useEffect(() => {
    if (selectedPackageData && selectedPackageData.package_subcourses) {
      const details: { name: string; type: string; code: string }[] = [];
      selectedPackageData.package_subcourses.forEach((data) => {
        data.subcourses?.forEach((subcourse) => {
          details.push({
            name: subcourse.name,
            type: "T",
            code: subcourse.code,
          });
          details.push({
            name: subcourse.name,
            type: "P",
            code: subcourse.code,
          });
        });
      });
      setSubcourseNames(Array.from(details));
    } else {
      setSubcourseNames([]);
    }
  }, [selectedPackageData]);

  const handlePackageChange = (value: number) => {
    setBtn(!!value);
  };

  const handleRemoveColumn = (index: number) => {
    setSubcourseNames((prevDetails) =>
      prevDetails.filter((_, i) => i !== index)
    );
  };

  const handleRatingChange = (value: number) => {
    setBtn(!!value);
  };

  const handleFormSubmit = (values: { rows: IFormValues[] }) => {
    setBtn(false);
    const { rows } = values;

    const payload: Payload = {
      branch_id:
        currentUserZone?.type !== ZoneType.PRIVATE ? selectedBranch : branch_id,
      package_id: selectedPackage,
      credits: [],
    };

    const packageData: Record<number, IPackageGrouped> = rows.reduce(
      (acc, row) => {
        const packageId = row.package;
        if (!acc[packageId]) {
          acc[packageId] = {
            package_id: packageId,
            points: [],
          };
        }

        const points = subcourseNames.map((subcourse) => {
          const type =
            subcourse.type === "T" ? "EXAM_THEORY" : "EXAM_PRACTICAL";

          return {
            sub_cource_code: subcourse.code,
            value:
              Number(row[`subcourses[${subcourseNames.indexOf(subcourse)}]`]) ||
              0,
            type,
          };
        });

        acc[packageId].points.push(...points);
        return acc;
      },
      {} as Record<number, IPackageGrouped>
    );

    payload.credits = Object.values(packageData);

    dispatch(createExamResult(trimObject(payload))).then((res) => {
      if (res.payload) {
        setBtn(false);
        form.resetFields();
        setSelectedBranch(undefined);
        setSelectedPackage(undefined);
        setSelectedPackageData(undefined);
      }
    });
  };

  return (
    <>
      <Skeleton active loading={loading} avatar>
        <Row
          align="middle"
          gutter={24}
          justify="space-between"
          className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"
        >
          <Col className="gx-mb-md-1">
            <ContainerHeader title="Exam Result" />
          </Col>

          <Col xs={24} sm={17} md={12} xl={10}>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
              {currentUserZone?.type !== ZoneType.PRIVATE &&
                branchState.branchesData.data?.rows.length > 0 && (
                  <Col xs={12} sm={12} className="gx-pr-sm-3">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      className="dash_input"
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder="Select Branch"
                      showSearch
                      showArrow
                      size="large"
                      onChange={(value: number | undefined) => {
                        setSelectedBranch(value);
                        setSelectedPackage(undefined);
                        setSelectedPackageData(undefined);
                        form.resetFields();
                      }}
                      filterOption={(value, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(value.toLowerCase().trim())
                      }
                    >
                      {branchState.branchesData.data?.rows
                        .filter((branch) => branch.status === true)
                        .map((branch) => (
                          <Option key={branch.id} value={branch.id}>
                            {branch.code}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                )}
              <Col xs={12} sm={12} className="gx-pr-sm-3">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  className="dash_input"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Select Package"
                  showSearch
                  showArrow
                  size="large"
                  onChange={(value: number | undefined) => {
                    setSelectedPackage(value);
                    const selectedData = filteredPackages.find(
                      (data) => data.id === value
                    );
                    setSelectedPackageData(
                      selectedData as IPackageRecord | undefined
                    );
                    form.resetFields();
                    setBtn(false);
                  }}
                  filterOption={(value, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  }
                  value={selectedPackage}
                >
                  {filteredPackages.map((data) => (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </div>
          </Col>
        </Row>

        <div>
          <Form
            form={form}
            name="examResultForm"
            initialValues={{
              rows: [""],
            }}
            onFinish={handleFormSubmit}
            className="exam-result"
          >
            <Card className="rnw-card table-card">
              <Form.List name="rows">
                {(fields, { add, remove }) => (
                  <div
                    className="exam-result-table"
                    style={{ borderRadius: "8px", overflow: "auto" }}
                  >
                    <table className="faculty-signing">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", maxWidth: "260px" }}>
                            Package
                          </th>
                          {subcourseNames.map((detail, index: number) => {
                            const maxLength = 13;
                            return (
                              <th key={index} className="sticky-top">
                                <div className="subcourse-remove-btn">
                                  <span>
                                    {showTooltip(
                                      `${detail.name} - (${detail.type})`,
                                      maxLength
                                    )}
                                  </span>
                                  <span>
                                    <Button
                                      style={{ marginLeft: "5px" }}
                                      icon={<CloseOutlined />}
                                      onClick={() => handleRemoveColumn(index)}
                                    />
                                  </span>
                                </div>
                              </th>
                            );
                          })}
                          {subcourseNames.length > 0 && (
                            <th style={{ width: "5%" }}>Total</th>
                          )}
                          <th style={{ width: "5%" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <tr key={key}>
                            <td
                              style={{
                                textAlign: "center",
                                maxWidth: "260px",
                                minWidth: "260px",
                              }}
                            >
                              <FloatLabel
                                label="Package"
                                placeholder="Select Package"
                                name={["rows", name, "package"]}
                                required
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "package"]}
                                  rules={rules.package}
                                >
                                  <Select
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    allowClear
                                    showSearch
                                    showArrow
                                    size="large"
                                    style={{ width: "100%" }}
                                    onChange={handlePackageChange}
                                  >
                                    {selectedPackageData &&
                                      filteredPackages
                                        .filter(
                                          (data) =>
                                            selectedPackageData?.id !== data.id
                                        )
                                        .map((data) => (
                                          <Option key={data.id} value={data.id}>
                                            {data.name}
                                          </Option>
                                        ))}
                                  </Select>
                                </Form.Item>
                              </FloatLabel>
                            </td>
                            {subcourseNames.map((subCourseName, subIndex) => (
                              <td
                                key={subIndex}
                                style={{
                                  textAlign: "center",
                                  maxWidth: "160px",
                                  minWidth: "160px",
                                }}
                              >
                                <FloatLabel
                                  label="Rating"
                                  placeholder="Select Rating"
                                  name={["rows", name, `subcourses[${subIndex}]`]}
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, `subcourses[${subIndex}]`]}
                                  >
                                    <Select
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                      size="large"
                                      style={{ width: "100%" }}
                                      showSearch
                                      allowClear
                                      showArrow
                                      onChange={handleRatingChange}
                                    >
                                      <Option value="0">0</Option>
                                      <Option value="1">1</Option>
                                      <Option value="2">2</Option>
                                      <Option value="3">3</Option>
                                      <Option value="4">4</Option>
                                      <Option value="5">5</Option>
                                      <Option value="6">6</Option>
                                      <Option value="7">7</Option>
                                      <Option value="8">8</Option>
                                      <Option value="9">9</Option>
                                      <Option value="10">10</Option>
                                    </Select>
                                  </Form.Item>
                                </FloatLabel>
                              </td>
                            ))}
                            {subcourseNames.length > 0 && (
                              <td style={{ textAlign: "center" }}>10</td>
                            )}
                            <td style={{ textAlign: "center" }}>
                              <AddRemoveButton
                                fieldsLength={fields.length}
                                index={index}
                                add={() => add()}
                                remove={() => remove(name)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </Form.List>
              <div className="text-align-right">
                <Form.Item>
                  <Button
                    className="btn-submit gx-mt-2"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    disabled={!btn}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Skeleton>
    </>
  );
};

export default ExamResult;
