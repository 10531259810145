import { useEffect, useState } from "react";
import {
  Alert,
  Card,
  Col,
  DatePicker,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import ContainerHeader from "src/components/ContainerHeader";
import { getAllTargetAchievementReportData } from "src/state/targetAchievement/targetAchievement.action";
import { useAppSelector } from "src/state/app.hooks";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { AppDispatch } from "src/state/app.model";
import { useDispatch } from "react-redux";
import { targetAdmissionSelector } from "src/state/targetAchievement/targetAchievement.reducer";
import { ColumnsType } from "antd/lib/table";
import { TargetReportData } from "src/services/targetAchievement/targetAchievement.model";
import {
  RatioColourType,
  TargetCategoryType,
} from "src/utils/constants/constant";
import { useSearchParams } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const AdmissionTargetAchievementReport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const branchState = useAppSelector(branchSelector);
  const targetAchievementState = useAppSelector(targetAdmissionSelector);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] = useState<
    [moment.Moment, moment.Moment] | null
  >();

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isAllBranch: true,
      })
    ).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const startDateParam = searchParams.get("start_date");
    const endDateParam = searchParams.get("end_date");

    if (startDateParam && endDateParam) {
      setSelectedRange([
        moment(startDateParam, "YYYY-MM"),
        moment(endDateParam, "YYYY-MM"),
      ]);
    } else {
      setSelectedRange(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedRange) {
      setLoading(true);
      const [start_date, end_date] = selectedRange;

      const payload = {
        start_date: start_date.format("YYYY-MM"),
        end_date: end_date.format("YYYY-MM"),
        noLimit: true,
        isReport: true,
        type: TargetCategoryType.NO_OF_ADMISSIONS,
        orderBy: "name",
        order: "ASC",
      };

      dispatch(getAllTargetAchievementReportData(payload)).finally(() =>
        setLoading(false)
      );
    } else {
      setLoading(false);
    }
  }, [selectedRange, TargetCategoryType.NO_OF_ADMISSIONS]);

  const handleRangeChange = (
    dates: [moment.Moment | null, moment.Moment | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setSelectedRange([dates[0], dates[1]]);
      setSearchParams({
        start_date: dates[0].format("YYYY-MM"),
        end_date: dates[1].format("YYYY-MM"),
      });
    } else {
      setSelectedRange(null);
      setSearchParams({});
      dispatch(getAllTargetAchievementReportData([]));
    }
  };

  const branchOptions = branchState?.branchesData?.data?.rows || [];
  const targetAchievementData =
    targetAchievementState.getAllTargetAchievementReportData.data?.map(
      (item) => ({
        ...item,
        data: Array.isArray(item.data) ? item.data : [],
      })
    ) || [];

  const columns: ColumnsType<TargetReportData> = [
    {
      title: "No.",
      dataIndex: "index",
      key: "index",
      render: (_: TargetReportData, __: TargetReportData, index: number) =>
        index + 1,
    },
    {
      title: "Branch Code",
      dataIndex: "branchCode",
      key: "branchCode",
      render: (_: TargetReportData, record: TargetReportData) => {
        const branch = branchOptions.find((b) => b.id === record.branch_id);
        return branch ? branch.code : "N/A";
      },
    },
    ...["Career Course", "45K to 85K", "Small Course", "Total"].map(
      (title, idx) => ({
        title,
        children: ["Target", "Achievement", "Ratio"].map(
          (subTitle, subIdx) => ({
            title: subTitle,
            dataIndex: `${title.toLowerCase().replace(/ /g, "")}${subTitle}`,
            key: `${title.toLowerCase().replace(/ /g, "")}${subTitle}`,
            render: (_: TargetReportData, record: TargetReportData) => {
              const data = record.data?.[idx];
              if (!data) return "0";

              const getBackgroundColor = (value: string) => {
                const ratio = parseFloat(value || "0.00%");
                if (ratio > 85) return RatioColourType.GREEN;
                if (ratio >= 60 && ratio <= 85) return RatioColourType.YELLOW;
                return RatioColourType.RED;
              };

              let cellValue = "0.00%";
              let backgroundColor = "transparent";

              switch (subTitle) {
                case "Target":
                  cellValue = data?.target?.total_value?.toString() || "-";
                  break;
                case "Achievement":
                  cellValue = data?.achievement?.total_value?.toString() || "-";
                  break;
                case "Ratio":
                  cellValue = data?.ratio?.percentage || "0.00%";
                  backgroundColor = getBackgroundColor(
                    data?.ratio?.percentage || "0.00%"
                  );
                  break;
                default:
                  break;
              }

              return (
                <div
                  className={`ratio-cell ${
                    backgroundColor !== "transparent"
                      ? "with-background"
                      : "transparent"
                  }`}
                  style={{
                    backgroundColor:
                      backgroundColor !== "transparent"
                        ? backgroundColor
                        : undefined,
                  }}
                >
                  {cellValue}
                </div>
              );
            },
          })
        ),
      })
    ),
  ];
  let message = "";
  if (!selectedRange) {
    message = "Apply filters to retrieve the data you are looking for.";
  } else if (selectedRange && targetAchievementData.length === 0) {
    message =
      "No targets found! Please add a Admission Target to display the data.";
  }

  return (
    <div className="rnw-main-content">
      <Row align="middle" justify="space-between" gutter={24} className="mb-20">
        <Col xxl={12}>
          <ContainerHeader title={"Admission Target vs Achievement Report"} />
        </Col>
        <Col xl={8} className="text-align-right">
          <RangePicker
            picker="month"
            onChange={handleRangeChange}
            value={selectedRange}
            format="MM-YYYY"
          />
        </Col>
      </Row>
      <Card className="rnw-card view-student admission-target">
        <Skeleton loading={loading} active>
          {message ? (
            <Alert
              message={message}
              type={message.includes("Apply") ? "info" : "warning"}
              showIcon
            />
          ) : (
            selectedRange &&
            targetAchievementData.length > 0 && (
              <Table
                className="table-striped"
                columns={columns}
                dataSource={targetAchievementData}
                rowKey="branch_id"
                bordered
                pagination={false}
              />
            )
          )}
        </Skeleton>
      </Card>
    </div>
  );
};

export default AdmissionTargetAchievementReport;
