export const questionsSampleData = [
    [
        {
          "Topic Type": "EXAM_THEORY",
          "Answer Type": "",
          "Question Type": "MCQ",
          "Severity Type": "HIGH",
          "Question": "What type of attack is this?",
          "Marks": "1",
          "Description": "Mcq Exam",
          "Tags": "CEH",
          "Option-1": "SQL injection",
          "Option-2": "Buffer overflow",
          "Option-3": "CSRF",
          "Option-4": "XSS",
          "Answer": "Buffer overflow"
        },
        {
          "Topic Type": "EXAM_PRACTICAL",
          "Answer Type": "URL",
          "Question Type": "SUBJECTIVE",
          "Severity Type": "LOW",
          "Question": "What is Nestjs?",
          "Marks": "1",
          "Description": "Subjective Exam",
          "Tags": "TAG",
          "Option-1": "",
          "Option-2": "",
          "Option-3": "",
          "Option-4": "",
          "Answer": ""
        }
    ],
]

export const topicSampleData = [
    [
        {
          "SR NO": "1",
          "Topic Type": "LECTURE",
          "Topic Sequence": "1",
          "Topic Name": "The Basics of Natural Language Processing",
          "Marks": "",
          "Duration": "8",
          "Sub Topic Sequence 1": "1",
          "Sub Topic Name 1": "What is NLP?\n- Need of NLP And Applications\n- Common NLP Tasks And Approaches\n- Challenges In NLP",
          "Sub Topic Sequence 2": "2",
          "Sub Topic Name 2": " What is NLP Pipeline?\n- Data Acquisition And Data Preparation\n- Feature Engineering\n- Modelling And Deployment",
          "Sub Topic Sequence 3": "",
          "Sub Topic Name 3": ""
        },
        {
          "SR NO": "2",
          "Topic Type": "PROJECT",
          "Topic Sequence": "2",
          "Topic Name": "NLP Project",
          "Marks": "10",
          "Duration": "1",
          "Sub Topic Sequence 1": "1",
          "Sub Topic Name 1": "- Project Day 1",
          "Sub Topic Sequence 2": "",
          "Sub Topic Name 2": "",
          "Sub Topic Sequence 3": "",
          "Sub Topic Name 3": ""
        },
        {
          "SR NO": "3",
          "Topic Type": "EXAM_THEORY",
          "Topic Sequence": "3",
          "Topic Name": "Theory Test",
          "Marks": "30",
          "Duration": "1",
          "Sub Topic Sequence 1": "1",
          "Sub Topic Name 1": "Theory Test",
          "Sub Topic Sequence 2": "",
          "Sub Topic Name 2": "",
          "Sub Topic Sequence 3": "",
          "Sub Topic Name 3": ""
        },
        {
          "SR NO": "4",
          "Topic Type": "VIVA",
          "Topic Sequence": "4",
          "Topic Name": "Technical Mock Round",
          "Marks": "20",
          "Duration": "1",
          "Sub Topic Sequence 1": "1",
          "Sub Topic Name 1": "Technical Mock Round",
          "Sub Topic Sequence 2": "",
          "Sub Topic Name 2": "",
          "Sub Topic Sequence 3": "",
          "Sub Topic Name 3": ""
        },
        {
          "SR NO": "5",
          "Topic Type": "EXAM_PRACTICAL",
          "Topic Sequence": "5",
          "Topic Name": "Practical Test",
          "Marks": "50",
          "Duration": "1",
          "Sub Topic Sequence 1": "1",
          "Sub Topic Name 1": "Practical Test",
          "Sub Topic Sequence 2": "",
          "Sub Topic Name 2": "",
          "Sub Topic Sequence 3": "",
          "Sub Topic Name 3": ""
        }
      ]
]