import { Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

interface AddRemoveButtonProps {
    fieldsLength: number;
    index: number;
    add: () => void;
    remove?: () => void;
    className?: string;
}

const AddRemoveButton = ({ fieldsLength, index, add, remove, className }: AddRemoveButtonProps) => {
    return (
        <div style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
            {fieldsLength === 1 ? (
                <Button
                    size="large"
                    type="primary"
                    onClick={add}
                    icon={<PlusOutlined />}
                    className={className}
                />
            ) : index === fieldsLength - 1 ? (
                <>
                    <Button
                        size="large"
                        icon={<MinusOutlined />}
                        onClick={remove}
                        className={className}
                    />
                    <Button
                        size="large"
                        type="primary"
                        onClick={add}
                        icon={<PlusOutlined />}
                        className={className}
                    />
                </>
            ) : (
                <Button
                    size="large"
                    icon={<MinusOutlined />}
                    onClick={remove}
                    className={className}
                />
            )}
        </div>
    );
};

export default AddRemoveButton;
